import React, { useEffect } from "react";
import useTitleStore from "../../stores/useTitleStore";

const Admin = () => {
  const { setTitle } = useTitleStore()

  useEffect(() => {
    setTitle({
      title: 'Administrativo',
      subTitle: 'Painel administrativo',
      routes: [],
    })
  }, [])

  return <>Admin</>
}

export default Admin;
