import { Layout as Lay } from "antd";
import React from "react";

import ProLayout from '@ant-design/pro-layout'
import useThemeStore from '~/stores/useThemeStore'
import useTitleStore from "../../stores/useTitleStore";

import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import logo from "~/assets/img/logo-icon.png";
import MenuRoutes, { filterMenu } from "~/components/Layout/menuRoutes";
import api from "~/services/axios";
import { proThemeDark, proThemeLight } from "~/styles/tokens";
import RightContent from "./RightContent";

const { Header, Content, Footer } = Lay; 

interface LayoutProps {
  children: React.ReactNode | null;
}

const Layout = ({ children }: LayoutProps) => {
  const { keycloak } = useKeycloak();

  const { TitleComponent, isDisabled: TitleOnScreen } = useTitleStore();
  const { setTheme, theme } = useThemeStore();

  const { data: me, isLoading } = useQuery(["me"], () => {
    return api
      .get("/api/user/me")
      .then((res) => res.data)
      .catch((_err) => {});
  });

  return (
    <ProLayout
      logo={logo}
      loading={isLoading}
      title={"Contemporâneo"}
      colorPrimary={"#247346"}
      navTheme={theme === "dark" ? "realDark" : "light"}
      contentWidth={"Fluid"}
      fixedHeader={true}
      fixSiderbar={true}
      layout={"mix"}
      splitMenus={true}
      //actionsRender={() => ["aaa"]}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (
          menuItemProps.isUrl ||
          !menuItemProps.path ||
          location.pathname === menuItemProps.path
        ) {
          return defaultDom;
        }
        return <Link to={menuItemProps.path}>{defaultDom}</Link>;
      }}
      actionsRender={() => [<RightContent key="right-content" />]}

      route={{
        routes: filterMenu(MenuRoutes, keycloak),
      }}
      token={theme === "dark" ? proThemeDark : proThemeLight}
      contentStyle={{
        padding: 0,
      }}
    >
      <>
        <TitleComponent />
        <div
          style={{
            padding: !TitleOnScreen ? "24px" : "48px 24px",
            position: !TitleOnScreen ? "relative" : "unset",
            top: !TitleOnScreen ? "-80px" : "0px",
          }}
        >
          {children}
        </div>
      </>
    </ProLayout>
  );
};

export default Layout;
