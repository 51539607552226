import { useQuery } from "@tanstack/react-query";
import { Button, Popover, Skeleton, Tag } from 'antd'
import React from 'react'
import api from "~/services/axios";
import { InlineAvatar } from "~/components/InlineAvatar";
import { capitalizeFirstLetter } from "~/helpers/Masks";

const CardUser = ({ id }) => {
  const { data, isLoading } = useQuery(["users", id], () =>
    api.get(`/api/users/${id}`).then((res) => res.data),
  );

  const content = (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {data?.profiles
          ?.split(`,`)
          ?.map((tag) => <Tag key={tag}>{capitalizeFirstLetter(tag)}</Tag>)}
      </div>
      <span>Username: {data?.username}</span>
      <br />
      <span>E-mail: {data?.email}</span>
      <br />
      <span>Tel: {data?.phone}</span>
    </div>
  );

  const title = (
    <>
      <InlineAvatar
        name={data?.name}
        size={24}
        style={{ marginRight: 10 }}
        src={data?.picture}
      />
      <span>{data?.name}</span>
    </>
  );

  return isLoading ? (
    <Skeleton.Input active={true} size={"small"} />
  ) : (
    <Popover content={content} title={title} trigger="click">
      <Button type={`link`}>{data.name}</Button>
    </Popover>
  );
};

export default CardUser;
