const colors = {
  primary: {
    _000: '#247346',
    _100: '#1C5836',
  },

  secondary: {
    _000: '#32325D',
  },

  gray: {
    _000: '#F5F7FA',
    _100: '#E5E9EE',
    _200: '#D1D5DA',
    _300: '#BABEC2',
    _400: '#979FA4',
    _500: '#737B8E',
    _600: '#5B6178',
    _700: '#444F60',
    _800: '#32325D',
    _850: '#20242c',
    _900: '#1C2028',
  },

  status: {
    success: '#2ECC71', // A soothing green, signifies positivity.
    error: '#E74C3C', // A strong red, symbolizes errors or issues.
    warning: '#F39C12', // A deep orange, represents caution.
    info: '#3498DB', // A calm blue, stands for information.
  },
};

export default colors;
