import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdEventAvailable,
  MdMoreVert,
  MdOutlineAttachMoney,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { InlineAvatar } from "~/components/InlineAvatar";
import Link from "~/components/Link";
import Table from "~/components/Table";
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import useTitleStore from "../../stores/useTitleStore";

interface DataType {
  key: React.Key;
  name: string;
  id: string;
  user_id: string;
  active: boolean;
  grades: Array<{
    year: number;
    type: string;
    type_year: string;
  } | null>;
  open_payments: number;
}

const Students = () => {
  const navigate = useNavigate();
  const { setTitle } = useTitleStore();

  const [form] = Form.useForm();

  const [modalNewStudent, setModalNewStudent] = useState(false);

  useEffect(() => {
    setTitle({
      title: "Alunos",
      subTitle: "Gerenciar alunos",
    });
  }, []);

  const { isLoading, data } = useQuery(["listAllStudents"], () => {
    return api
      .get("/api/students")
      .then((res) => {
        return res.data.map((student: any) => ({
          ...student,
          grades: [
            student.grades.find(
              (x: any) => x.year === new Date().getFullYear(),
            ),
          ],
        }));
      })
      .catch(() => {});
  });

  const gradesFilter = (data || [])
    .map((user) => ({
      text: user.grades[0]?.type || "Sem curso no ano atual",
      value: user.grades[0]?.type || "Sem curso no ano atual",
    }))
    .filter((v, i, a) => a.findIndex((t) => t.text === v.text) === i);

  const gradesOnFilter = (
    value: string | number | boolean,
    record: DataType,
  ) => {
    if (value === "Sem curso no ano atual") {
      return record.grades[0]?.type === undefined;
    }
    return record.grades[0]?.type === value;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
      filters: (data || [])
        .map((user) => ({
          text: user.name,
          value: user.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value: string | number | boolean, record) =>
        record.name.startsWith(value.toString()),
      filterSearch: true,
      sorter: (a, b) => b.name.localeCompare(a.name),
      render: (_, item) => (
        <Link to={`/portal/students/${item.id}?view=profile`}>
          <InlineAvatar name={item.name} src={item.picture} />
          {item.name}
        </Link>
      ),
    },
    {
      title: "Usuário",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Curso",
      dataIndex: "grades",
      key: "grades",
      filters: gradesFilter,
      onFilter: (value: string | number | boolean, record) =>
        gradesOnFilter(value, record),
      filterSearch: true,
      render: (_, item) => (
        <Space>
          {
            // render only the grade of the current year
            item.grades
              .filter((grade) => grade?.year === new Date().getFullYear())
              .map((grade) => (
                <Tag key={grade?.type} color="blue">
                  {grade?.type}
                </Tag>
              ))
          }
        </Space>
      ),
    },
    {
      title: (
        <Tooltip placement="topLeft" title="Possui pagamentos pendentes?">
          <MdOutlineAttachMoney size={18} />
        </Tooltip>
      ),
      dataIndex: "open_payments",
      key: "open_payments",
      width: 10,
      filters: [
        {
          text: "Sim",
          value: 1,
        },
        {
          text: "Não",
          value: 0,
        },
      ],
      onFilter: (value: number, record) =>
        value === 1 ? record.open_payments > 0 : record.open_payments === 0,
      render: (_, item) => (
        <Tooltip
          placement="topLeft"
          title={
            item?.open_payments
              ? `Possui ${item?.open_payments} pagamentos em aberto`
              : "Não possui pagamentos em aberto"
          }
        >
          <MdOutlineAttachMoney
            color={item?.open_payments > 0 ? "red" : "grey"}
            size={18}
          />
        </Tooltip>
      ),
    },
    {
      title: (
        <Tooltip placement="topLeft" title="Possui aulas futuras?">
          <MdEventAvailable size={18} />
        </Tooltip>
      ),
      dataIndex: "future_events",
      key: "future_events",
      width: 10,
      filters: [
        {
          text: "Sim",
          value: 1,
        },
        {
          text: "Não",
          value: 0,
        },
      ],
      onFilter: (value: number, record) =>
        value === 1 ? record.future_events > 0 : record.future_events === 0,
      render: (_, item) => (
        <Tooltip
          placement="topLeft"
          title={
            item?.future_events
              ? `Possui ${item?.future_events} aulas futuras`
              : "Não possui aulas futuras"
          }
        >
          <MdEventAvailable
            color={item?.future_events > 0 ? "green" : "grey"}
            size={18}
          />
        </Tooltip>
      ),
    },
    {
      title: "Ações",
      key: "action",
      width: "30px",
      render: (_, item) => (
        <Dropdown
          menu={{
            items: [
              {
                label: "Perfil",
                onClick: () =>
                  navigate(`/portal/students/${item.id}?view=profile`),
                key: "profile",
              },
              {
                label: "Agendar aula",
                onClick: () =>
                  navigate(
                    `/portal/events/create?student=${item.id}&origin=student`,
                  ),
                key: "schedule",
              },
              {
                label: "Ver extrato",
                onClick: () =>
                  navigate(`/portal/students/${item.id}?view=statement`),
                key: "statement",
              },
              {
                label: "Desempenho",
                onClick: () =>
                  navigate(`/portal/students/${item.id}?view=performance`),
                key: "performance",
              },
              {
                label: "Editar",
                key: "edit",
                onClick: () => {},
                disabled: true,
              },
            ],
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MdMoreVert />
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <Space direction={"vertical"} size={16} style={{ display: "flex" }}>
      <Card
        title="Listagem de Alunos"
        extra={
          <Button
            icon={<MdAdd />}
            type="primary"
            onClick={() => setModalNewStudent(true)}
          >
            Novo Aluno
          </Button>
        }
      >
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          size={"small"}
        />
      </Card>

      <Modal
        open={modalNewStudent}
        onCancel={() => setModalNewStudent(false)}
        title="Novo Aluno"
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            api
              .post("/api/students", values)
              .then((response) => {
                queryClient.invalidateQueries(["listAllStudents"]);

                notification.success({
                  message: "Aluno criado com sucesso!",
                });

                navigate(`/portal/students/${response.data.id}?view=profile`);
              })
              .catch((error) => {
                notification.error({
                  message: "Erro ao criar aluno",
                  description: error.response.data.message,
                });
              });
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="E-mail" name="email">
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[
              {
                pattern: new RegExp(/^[0-9\b]+$/),
                message: "Somente números",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
};

export default Students;
