import { useQuery } from "@tanstack/react-query";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  DatePicker,
  Modal,
  notification,
  Row,
  Segmented,
  Tooltip,
  Typography,
} from 'antd'
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegCalendarAlt,
  FaRegClock,
  FaThList,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import EventBullet from "~/components/Calendar/EventBullet";
import { InlineAvatar } from "~/components/InlineAvatar";
import Loading from "~/components/Loading";
import { CalendarContent } from "~/pages/Schedule/styles";
import api from "~/services/axios";
import useThemeStore from "~/stores/useThemeStore";
import colors from "~/styles/colors";
import useTitleStore from "../../stores/useTitleStore";

const Schedule = () => {
  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const query = new URLSearchParams(useLocation().search);
  const { setTitle } = useTitleStore();

  const { gray } = colors;

  const [currentDate, setCurrentDate] = useState(() =>
    query.get("date") ? dayjs(query.get("date")) : dayjs(),
  );
  const [view, setView] = useState(query.get("view") ?? "calendar");
  const [eventRoom, setEventRoom] = useState(undefined);

  const [modalityView, setModalityView] = useState("Online");

  const { Text, Link } = Typography;

  useEffect(() => {
    setTitle({
      title: "Agenda",
      subTitle: "Gerenciar agenda",
    });
  }, []);

  useEffect(() => {
    query.set("date", currentDate.format("YYYY-MM-DD"));
    query.set("view", view);
    navigate({ search: query.toString() }, { replace: true });
  }, [currentDate]);

  const onSelect = (newValue: Dayjs) => {
    setCurrentDate(newValue);
  };

  const { isLoading, data: events } = useQuery(
    [
      "listAllEvents",
      currentDate ? currentDate.set("date", 1).format("YYYY-MM-DD") : "",
    ],
    () => {
      return api
        .get("/api/events", {
          params: {
            date: currentDate
              ? currentDate.set("date", 1).format("YYYY-MM-DD")
              : "",
            datailed: true,
          },
        })
        .then((res) => res.data)
        .catch((err) => {});
    },
  );

  const { isLoading: isLoadingModalities, data: modalities } = useQuery(
    ["listAllModalities"],
    () => {
      return api
        .get("/api/modalities")
        .then((res) => res.data)
        .catch((err) => {});
    },
  );

  const { isLoading: isLoadingRooms, data: rooms } = useQuery(
    ["listAllRooms"],
    () => {
      return api
        .get("/api/rooms")
        .then((res) => {
          // order objects by name
          res.data.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });

          return res.data;
        })
        .catch((err) => {});
    },
  );

  // filter rooms by modalityview
  const filteredRooms = rooms?.filter((room) => {
    // get modality id by name (online or presential)
    const modalityId = modalities?.find((modality) => {
      return modality.name === modalityView;
    })?.id;

    return room.modality_id === modalityId;
  });

  const monthCellRender = (value: Dayjs) => {
    const listData = events?.filter((event) => {
      return dayjs(event.date).isSame(value, "month");
    });

    return (
      <div className="events">
        {listData?.map((item) => <EventBullet key={item.id} event={item} />)}
      </div>
    );
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = events?.filter((event) => {
      return (
        dayjs(event.date).isSame(value, "day") &&
        event.status.name !== "Cancelado"
      );
    });

    return (
      <div className="events">
        {listData?.map((item) => <EventBullet key={item.id+"_"+value.toISOString()} event={item} />)}
      </div>
    );
  };

  const EventCard = ({ item, index }) => {
    const duration = (start: string, end: string) => {
      return (
        Math.abs(
          new Date(`2021-01-01 ${start?.substring(0, 5)}`).getTime() -
            new Date(`2021-01-01 ${end?.substring(0, 5)}`).getTime(),
        ) / 36e5
      );
    };

    const top = 41.14 + duration("07:00:00", item?.int_time) * 64 - 4;
    const height = duration(item?.int_time, item?.end_time) * 64 - 1;

    const { isLoading: isLoadingEvent, data: event } = useQuery(
      ["getEventById", item.id],
      () => {
        return api
          .get(`/api/events/${item.id}`)
          .then((res) => res.data)
          .catch((err) => {});
      },
      {
        staleTime: 1000 * 60 * 20,
        cacheTime: 1000 * 60 * 20,
      },
    );

    return (
      <Draggable draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,

              position: snapshot.isDragging ? "unset" : "absolute",
              top: snapshot.isDragging ? "0" : top,
              width: "100%",
              maxHeight: height,
              height: `${height}px`,
            }}
          >
            <div
              style={{
                backgroundColor: `rgba( 255, 255, 255, ${
                  isLoadingEvent ? 1 : 0
                })`,
                borderRadius: 4,

                padding: "0px 8px 0px 0px",
              }}
            >
              <Badge.Ribbon
                text={event?.subject?.name}
                color={event?.subject?.color}
              >
                <Card
                  size="small"
                  style={{
                    maxHeight: height,
                    height,
                    border: `1px solid ${event?.subject?.color}`,
                  }}
                  styles={{
                    body: {
                      height: "100%",
                      padding: "0 8px",
                    },
                  }}
                  bordered={true}
                  loading={isLoadingEvent}
                >
                  {!isLoadingEvent && (
                    <Row
                      style={{
                        height: "100%",
                        padding: "8px",
                        alignContent: "space-between",
                      }}
                    >
                      <Col span={24}>
                        {height >= 64 && (
                          <Text type="secondary">{event?.type_class}</Text>
                        )}
                      </Col>
                      <Col span={20}>
                        <Avatar.Group
                          maxCount={4}
                          maxStyle={{
                            color: "#f56a00",
                            backgroundColor:
                              theme === "dark" ? gray._850 : "#fde3cf",
                          }}
                        >
                          {[...event?.teachers, ...event?.students].map(
                            (member) => (
                              <Tooltip
                                key={member?.id}
                                title={member?.user?.name}
                              >
                                <InlineAvatar
                                  name={member?.user?.name}
                                  src={member?.user?.picture}
                                  style={{
                                    marginRight: 0,
                                    backgroundColor: "#87d068",
                                  }}
                                />
                              </Tooltip>
                            ),
                          )}
                        </Avatar.Group>
                      </Col>
                      <Col span={4}>
                        <Link
                          onClick={() => {
                            navigate(`/portal/events/${event.id}`);
                          }}
                        >
                          <a href={`/portal/events/${event.id}`}>Ver</a>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Badge.Ribbon>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const addDay = () => {
    const newDate = currentDate.add(1, "day");
    setCurrentDate(newDate);
  };

  const subtractDay = () => {
    const newDate = currentDate.subtract(1, "day");
    setCurrentDate(newDate);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceEvent = events.find((event) => event.id === draggableId);
      const roomDestination = rooms.find(
        (room) => room.id === destination.droppableId,
      );
      const roomSource = rooms.find((room) => room.id === source.droppableId);

      setEventRoom({ sourceEvent, roomDestination });
    }
  };

  const setEventNewRoom = async ({ sourceEvent, roomDestination }) => {
    notification.error({
      message: "Recurso desabilitado pelo administrador",
      description: `O recurso de mover aulas foi desabilitado pelo administrador`,
    });

    setEventRoom(undefined);
  };

  return (
    <CalendarContent>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Row gutter={[16, 16]}>
              {isLoading && (
                <Col span={24}>
                  <Loading />
                </Col>
              )}

              {!isLoading && (
                <>
                  <Col span={24}>
                    <Row gutter={[16, 16]} justify={"end"}>
                      <Col span={8}>
                        <Segmented
                          block={true}
                          onChange={(e) => {
                            setView(e);
                            query.set("view", e);
                            navigate(
                              { search: query.toString() },
                              { replace: true },
                            );
                          }}
                          defaultValue={view}
                          options={[
                            {
                              label: "Calendário",
                              value: "calendar",
                              icon: <FaRegCalendarAlt />,
                            },
                            {
                              label: "Lista",
                              value: "list",
                              icon: <FaThList />,
                            },
                          ]}
                          onResize={undefined}
                          onResizeCapture={undefined}
                        />
                      </Col>
                    </Row>
                  </Col>

                  {view === "calendar" && (
                    <Col span={24}>
                      <Alert
                        message={`Você selecionou a data: ${currentDate?.format(
                          "DD/MM/YYYY",
                        )}`}
                      />
                      <Calendar
                        //dateCellRender={dateCellRender}
                        //monthCellRender={monthCellRender}
                        //cellRender={dateCellRender}
                        //fullCellRender={monthCellRender}

                        cellRender={(currentDate) => dateCellRender(currentDate)}
                        onSelect={onSelect}
                        className="calendar"
                        mode={"month"}
                        defaultValue={currentDate}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  )}

                  {view === "list" && (
                    <Col span={24}>
                      <Row gutter={[16, 16]} justify={"space-between"}>
                        <Col span={10}>
                          <Button onClick={() => subtractDay()}>
                            <FaChevronLeft />
                          </Button>
                          <DatePicker
                            presets={[
                              { label: "Hoje", value: dayjs().add(0, "d") },
                              { label: "Ontem", value: dayjs().add(-1, "d") },
                              {
                                label: "Semana passada",
                                value: dayjs().add(-7, "d"),
                              },
                              {
                                label: "Mês passado",
                                value: dayjs().add(-1, "month"),
                              },
                            ]}
                            allowClear={false}
                            value={currentDate}
                            onChange={(date) => {
                              // set date to selected value as dayjs
                              const newDate = dayjs(date);
                              setCurrentDate(newDate);
                            }}
                            format={"DD/MM/YYYY"}
                          />
                          <Button onClick={() => addDay()}>
                            <FaChevronRight />
                          </Button>
                        </Col>
                        <Col span={10}>
                          <Segmented
                            block={true}
                            onChange={(e) => setModalityView(e)}
                            defaultValue={modalityView}
                            options={modalities?.map((item) => {
                              return {
                                value: item.name,
                                label: item.name,
                              };
                            })}
                            onResize={undefined}
                            onResizeCapture={undefined}
                          />
                        </Col>

                        <Col span={24}>
                          <Row
                            gutter={[0, 16]}
                            wrap={false}
                            style={{
                              height: "64vh",
                              overflow: "auto",
                            }}
                          >
                            <Col span={2}>
                              <Card
                                title={
                                  <FaRegClock
                                    style={{
                                      margin: "0 auto",
                                    }}
                                  />
                                }
                                styles={{
                                  body: {
                                    padding: 0,
                                  },
                                  header: {
position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                  backgroundColor:
                                    theme === "dark" ? gray._850 : "#f0f2f5",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  }
                                }}
                                size={"small"}
                              >
                                {
                                  // map 24 hours
                                  [...Array(18).keys()].map((hour) => {
                                    const calcHour = hour + 7;
                                    return (
                                      <Col key={calcHour} span={24}>
                                        <div
                                          style={{
                                            width: "100%",
                                            height: "32px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            backgroundColor:
                                              theme === "dark"
                                                ? gray._900
                                                : "#fdfdfd",
                                          }}
                                        >
                                          {calcHour}:00
                                        </div>
                                        {calcHour !== 24 && (
                                          <div
                                            style={{
                                              width: "100%",
                                              height: "32px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignContent: "center",
                                              backgroundColor:
                                                theme === "dark"
                                                  ? gray._850
                                                  : "#f0f2f5",
                                            }}
                                          >
                                            {calcHour}:30
                                          </div>
                                        )}
                                      </Col>
                                    );
                                  })
                                }
                              </Card>
                            </Col>

                            <DragDropContext onDragEnd={onDragEnd}>
                              {filteredRooms?.map((room) => {
                                return (
                                  <Droppable
                                    key={room.id}
                                    droppableId={room.id}
                                    direction="horizontal"
                                    renderClone={(
                                      provided,
                                      snapshot,
                                      rubric,
                                    ) => (
                                      <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        style={{
                                          ...provided.draggableProps.style,
                                          backgroundColor: "#247346",
                                          color: "white",
                                          border: "1px solid #247346",
                                          borderRadius: "4px",
                                          boxShadow:
                                            "0 2px 8px rgba(0, 0, 0, 0.15)",
                                          padding: "8px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignContent: "center",
                                        }}
                                      >
                                        Mover aula de {room.name} para...
                                      </div>
                                    )}
                                  >
                                    {(
                                      provided: DroppableProvided,
                                      snapshot,
                                    ) => (
                                      <Col
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        flex={"1 1 auto"}
                                        style={{
                                          maxWidth: "450px",
                                          width: "100%",
                                        }}
                                      >
                                        <Card
                                          title={room.name}
                                          styles={{
                                            header: {
                                              position: "sticky",
                                              top: 0,
                                              height: "32px",
                                              backgroundColor:
                                                snapshot.isDraggingOver
                                                  ? theme === "dark"
                                                    ? gray._600
                                                    : "#b0d197"
                                                  : theme === "dark"
                                                    ? gray._850
                                                    : "#f0f2f5",
                                              zIndex: 1,
                                            },
                                            body: {
                                              overflow: "hidden",
                                              padding: 0,
                                              height: 32 * 35 + "px",
                                              background:
                                                theme === "dark"
                                                  ? "repeating-linear-gradient(#1c2028, #1c2028 32px, #20242c 32px, #20242c 64px)"
                                                  : "repeating-linear-gradient(#fff, #fff 32px, #f0f2f5 32px, #f0f2f5 64px)",
                                              // height: 'calc(100% - 38px)',
                                              border: snapshot.isDraggingOver
                                                ? "1px solid #b0d197"
                                                : "none",
                                            },
                                          }}
                                          size={"small"}
                                        >
                                          {events
                                            ?.filter(
                                              (event) =>
                                                event.room.id === room.id &&
                                                event?.status?.name !==
                                                  "Cancelado" &&
                                                dayjs(event.date).isSame(
                                                  currentDate,
                                                  "day",
                                                ),
                                            )
                                            ?.map((event, index) => {
                                              return (
                                                <EventCard
                                                  key={event?.id}
                                                  index={index}
                                                  item={event}
                                                />
                                              );
                                            })}
                                        </Card>
                                        {provided.placeholder}
                                      </Col>
                                    )}
                                  </Droppable>
                                );
                              })}
                            </DragDropContext>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Mudar local da aula"
        open={eventRoom}
        onOk={() => {
          setEventNewRoom(eventRoom);
        }}
        onCancel={() => {
          setEventRoom(undefined);
        }}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Mudar aula para a sala <b>{eventRoom?.roomDestination?.name}</b>?
        </p>
      </Modal>
    </CalendarContent>
  );
};

export default Schedule;
