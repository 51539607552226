import { useQuery } from "@tanstack/react-query";
import { Button, Col, Popover, Result, Tag } from 'antd'
import React, { useState } from 'react'
import api from "~/services/axios";
import useContextStore from "~/stores/useContextStore";
import Table from "~/components/Table";
import { MaskDateWithTime } from "~/helpers/Masks";
import CardUser from "~/components/CardUser";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface LogsProps {
  id: string;
}

const Logs = ({ id }: LogsProps) => {
  const { currentProfile } = useContextStore();
  const [logsPage, setLogsPage] = useState({ page: 1, pageSize: 10 });

  const { data: logs, isLoading: isLoadingLogs } = useQuery(
    ["student_logs", id, logsPage?.page, logsPage?.pageSize],
    () =>
      api
        .get(`/api/logs`, {
          params: {
            page: logsPage?.page || 1,
            pageSize: logsPage?.pageSize || 10,
            search: id,
          },
        })
        .then((res) => res.data),
    {
      enabled: currentProfile === "admin",
    },
  );

  if (currentProfile !== "admin") {
    return (
      <Col span={24} style={{ width: "100%" }}>
        <Result
          status="403"
          title="403"
          subTitle="Desculpe, você não tem permissão para acessar esta página."
          extra={<Button type="primary">Voltar Home</Button>}
        />
      </Col>
    );
  }

  return (
    <Col span={24} style={{ width: "100%" }}>
      <Table
        rowKey="id"
        columns={[
          {
            title: "Data",
            dataIndex: "created_at",
            key: "created_at",
            render: (value) => {
              return MaskDateWithTime(value);
            },
          },
          {
            title: "Usuário",
            dataIndex: "user_id",
            key: "user_id",
            render: (value) => {
              return <CardUser id={value} />;
            },
          },
          {
            title: "Ação",
            dataIndex: "type",
            key: "type",
            render: (value) => {
              return <Tag>{value}</Tag>;
            },
          },
          {
            title: "IP",
            dataIndex: "user_ip",
            key: "user_ip",
            render: (text, item) => {
              return (
                <Popover
                  content={
                    <>
                      <span>IP: {item.user_ip}</span>
                      <br />
                      <span>Dispositivo: {item.user_device}</span>
                      <br />
                      <span>OS: {item.user_os}</span>
                      <br />
                      <span>OS: {item.user_os}</span>
                      <br />
                      <span>Browser: {item.user_browser}</span>
                      <br />
                      <span>Browser Version: {item.user_browser_version}</span>
                      <br />
                      <span>
                        Visualização:{" "}
                        {item.user_is_mobile
                          ? "Mobile"
                          : item.user_is_desktop
                            ? "Desktop"
                            : "Unknown"}
                      </span>
                    </>
                  }
                  title={""}
                  trigger="click"
                >
                  <Button type={`link`}>{text}</Button>
                </Popover>
              );
            },
          },
          {
            title: "Descrição",
            dataIndex: "message",
            key: "message",
            render: (value) => {
              return (
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {value}
                </ReactMarkdown>
              );
            },
          },
        ]}
        dataSource={logs?.data}
        loading={isLoadingLogs}
        pagination={{
          pageSize: logs?.pageSize || 10,
          total: logs?.total || 0,
          onChange: (page, pageSize) => {
            setLogsPage({ page, pageSize });
          },
        }}
      />
    </Col>
  );
};

export default Logs;
