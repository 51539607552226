import { useQuery } from "@tanstack/react-query";
import { Button, Col, notification, Popover, Row, Tag, Typography } from 'antd'
import React, { memo, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { TfiClose } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { InlineAvatar } from "~/components/InlineAvatar";
import Loading from "~/components/Loading";
import { MaskDate } from "~/helpers/Masks";
import api from "~/services/axios";

interface EventProps {
  id: string;
  date: string;
  int_time: string;
  end_time: string;
  room_id: string;
  subject_id: string;
  modality_id: string;
  grade_id: string;
  students: Array<{
    id: string;
    user_id: string;
    user: {
      id: string;
      name: string;
      picture: string;
    };
  } | null>;
  teachers: Array<{
    id: string;
    user_id: string;
  } | null>;
  status: {
    id: string;
    name: string;
    color: string;
  };
}

interface TeacherProps {
  id: string;
  user_id: string;
  active: boolean;
  name: string;
  email: string;
  username: string;
  phone: string;
  picture: string;
}

interface EventBulletProps {
  event: EventProps;
}

const EventBullet = ({ event }: EventBulletProps) => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [eventFull, setEventFull] = useState(false);
  const [isLoadingEvent, setIsLoadingEvent] = useState(true);

  const { Text } = Typography;

  const hide = () => {
    setClicked(false);
    setHovered(false);
  };

  const handleHoverChange = (open: boolean) => {
    setHovered(open);
  };

  const handleClickChange = (open: boolean) => {
    setHovered(false);
    setClicked(open);
  };

  const { isLoading: isLoadingTeacher, data: teacher } = useQuery<TeacherProps>(
    ["getTeachers", event?.teachers[0]?.id],
    () => {
      return api
        .get(`/api/teachers/${event?.teachers[0]?.id}`)
        .then((res) => res.data)
        .catch((err) => {});
    },
    {
      cacheTime: 1000 * 60 * 20,
      staleTime: 1000 * 60 * 20,
    },
  );
  useEffect(() => {
    if (clicked) {
      api
        .get(`/api/events/${event.id}`, { params: { datailed: true } })
        .then((res) => {
          setEventFull(res.data);
          setIsLoadingEvent(false);
        })
        .catch((err) =>
          notification.error({
            message: "Erro ao carregar aula",
          }),
        );
    }
  }, [clicked]);

  const hoverContent = (
    <>
      <Row gutter={[8, 8]} style={{ width: "300px" }}>
        <Col span={24}>
          <Text type="secondary">Aula - {event?.modality?.name}</Text> <br />
          <Tag color={event?.status?.color}>{event?.status?.name}</Tag>
        </Col>
        <Col span={8}>
          <Text type="secondary">{MaskDate(event?.date)}</Text>
        </Col>
        <Col span={8}>
          <Text type="secondary">
            {event?.int_time?.substring(0, 5)} -
            {event?.end_time?.substring(0, 5)}
          </Text>
        </Col>
        <Col span={8}>
          <Text type="secondary">
            {Math.abs(
              new Date(
                `2021-01-01 ${event?.int_time?.substring(0, 5)}`,
              ).getTime() -
                new Date(
                  `2021-01-01 ${event?.end_time?.substring(0, 5)}`,
                ).getTime(),
            ) / 36e5}
            h
          </Text>
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => {
              navigate(`/portal/events/${event.id}`, { state: { event } });
            }}
          >
            Ver detalhes
          </Button>
        </Col>
      </Row>
    </>
  );

  const clickContent = (
    <>
      {isLoadingEvent ? (
        <Loading style={{ minHeight: "150px" }} />
      ) : (
        <Row gutter={[8, 8]} style={{ width: "300px" }}>
          {console.log(eventFull)}
          <Col span={24}>
            <Text type="secondary">Aula - {eventFull?.modality?.name}</Text>
            <br />
            <Tag color={eventFull?.status?.color}>
              {eventFull?.status?.name}
            </Tag>
          </Col>
          <Col span={8}>
            <Text type="secondary">{MaskDate(eventFull?.date)}</Text>
          </Col>
          <Col span={8}>
            <Text type="secondary">
              {eventFull?.int_time?.substring(0, 5)} -
              {eventFull?.end_time?.substring(0, 5)}
            </Text>
          </Col>
          <Col span={8}>
            <Text type="secondary">
              {Math.abs(
                new Date(
                  `2021-01-01 ${eventFull?.int_time?.substring(0, 5)}`,
                ).getTime() -
                  new Date(
                    `2021-01-01 ${eventFull?.end_time?.substring(0, 5)}`,
                  ).getTime(),
              ) / 36e5}
              h
            </Text>
          </Col>
          <Col span={24}>
            <Tag color={eventFull?.room?.color}>{eventFull?.room?.name}</Tag>
            <Tag color="blue">{eventFull?.modality?.name}</Tag>
            <Tag color={eventFull?.grade?.color}>{eventFull?.grade?.type}</Tag>
          </Col>

          <Col span={24}>
            <Text type="secondary">Professor</Text>
          </Col>
          {eventFull?.teachers?.map((teacher) => (
            <React.Fragment key={teacher?.user?.name}>
              <Col span={3}>
                <InlineAvatar
                  name={teacher?.user?.name}
                  src={teacher?.user?.picture}
                  size={24}
                />
              </Col>
              <Col span={21}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text>{teacher?.user?.name}</Text>
                  <div>
                    <Button shape="circle" disabled icon={<FaWhatsapp />} />
                    <Button
                      style={{ marginLeft: 8 }}
                      shape="circle"
                      disabled
                      icon={<HiOutlineMail />}
                    />
                  </div>
                </div>
                <div>
                  <Tag color={eventFull?.subject?.color}>
                    {eventFull?.subject?.name}
                  </Tag>
                </div>
              </Col>
            </React.Fragment>
          ))}

          <Col span={24}>
            <Text type="secondary">Alunos</Text>
          </Col>
          {eventFull?.students?.map((student) => (
            <React.Fragment key={student?.user?.name}>
              <Col span={3}>
                <InlineAvatar
                  name={student?.user?.name}
                  src={student?.user?.picture}
                  size={24}
                />
              </Col>
              <Col span={21}>
                <div>
                  <Text>{student?.user?.name}</Text>
                </div>
              </Col>
            </React.Fragment>
          ))}

          <Col span={24}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                navigate(`/portal/events/${event.id}`);
              }}
            >
              Ver detalhes
            </Button>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <Popover
      style={{ maxWidth: 100 }}
      content={hoverContent}
      trigger="hover"
      open={hovered}
      onOpenChange={handleHoverChange}
    >
      <Popover
        content={
          !eventFull ? (
            <Loading style={{ minHeight: "150px" }} />
          ) : (
            <>{clickContent}</>
          )
        }
        trigger="click"
        title={
          <a style={{ marginLeft: "90%" }} onClick={hide}>
            <TfiClose />
          </a>
        }
        open={clicked}
        onOpenChange={handleClickChange}
      >
        <Button
          loading={isLoadingTeacher}
          icon={
            <InlineAvatar
              name={teacher?.name}
              src={teacher?.picture}
              size="small"
            />
          }
          style={{ height: "auto" }}
          block={true}
          className="event-bullet"
        >
          {event?.int_time.substring(0, 5)}
        </Button>
      </Popover>
    </Popover>
  );
};

export default memo(EventBullet);
