export const onFilter = (
  value: string | number | boolean,
  key: string | string[],
  record: any,
) => {
  if (Array.isArray(key)) {
    // se a chave é uma matriz, verifica se o valor está incluído em qualquer um dos campos record[key[i]]
    return record[key[0]]?.find((item) => item[key[1]] === value);
  }

  if (typeof value === 'string') {
    // se o valor é uma string, verifica se está incluído no campo record[key], ignorando a capitalização
    return record[key].toLowerCase().includes(value.toLowerCase());
  } else if (typeof value === 'number') {
    // se o valor é um número, assume que record[key] pode ser um número e converte-o antes de comparar
    return Number(record[key]) === value;
  } else if (typeof value === 'boolean') {
    // se o valor é um boolean, assume que record[key] pode ser um boolean e converte-o antes de comparar
    return Boolean(record[key]) === value;
  }
  return false; // Caso padrão, você pode ajustar isso de acordo com suas necessidades.
};

/**
 Usado para gerar opções de filtro para campos de seleção
    @param data - dados brutos
    @param key - chave para gerar opções
    @returns opções de filtro

    Exemplo de uso:
    generateFilter(data || [], 'name');
    generateFilter(data?.map(user => user.subjects).flat() || [], 'name');
 **/
export function generateFilter(data: any[], key: string) {
  if (!data?.length) return [];

  return data
    .map((item) => ({
      text: item[key],
      value: item[key],
    }))
    .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i)
    .sort((a, b) => a.text.localeCompare(b.text));
}
