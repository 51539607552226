import { useQuery } from "@tanstack/react-query";
import { Form, Input, Modal, notification, Select } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import api from "~/services/axios";
import { queryClient } from '~/services/queryClient';

interface CreateNewTeacherModalProps {
  modalNewTeacher: boolean;
  setModalNewTeacher: (value: boolean) => void;
}

const CreateNewTeacherModal = ({
  modalNewTeacher,
  setModalNewTeacher,
}: CreateNewTeacherModalProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isLoadingGroups, data: dataGroups } = useQuery(
    ["listAllGroups"],
    () => {
      return api
        .get("/api/prices/groups")
        .then((res) => {
          return res.data.map((group: any) => ({
            label: group.name,
            value: group.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .catch(() => {});
    },
  );

  const { isLoading: isLoadingSubjects, data: dataSubjects } = useQuery(
    ["listAllSubjects"],
    () => {
      return api
        .get("/api/subjects", {
          params: {
            show_all: true,
          },
        })
        .then((res) => {
          return res.data.map((subject: any) => ({
            label: subject.name,
            value: subject.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .catch(() => {});
    },
  );

  const onFinish = async (values: any) => {
    api
      .post("/api/teachers", values)
      .then((response) => {
        queryClient.invalidateQueries(["listAllTeachers"]);

        notification.success({
          message: "Professor criado com sucesso!",
        });

        navigate(`/portal/teachers/${response.data.id}?view=profile`);
        setIsSubmitting(false);
      })
      .catch((error) => {
        notification.error({
          message: "Erro ao criar professor",
          description: error.response.data.message,
        });
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      open={modalNewTeacher}
      onCancel={() => setModalNewTeacher(false)}
      title="Novo Professor"
      onOk={() => {
        setIsSubmitting(true);
        form.submit();
      }}
      okButtonProps={{
        loading: isLoadingGroups || isLoadingSubjects || isSubmitting,
      }}
    >
      <Form form={form} onFinish={onFinish} layout={"vertical"}>
        <Form.Item
          label="Nome"
          name="name"
          rules={[{ required: true, message: "Campo obrigatório" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[{ required: true, message: "Campo obrigatório" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Telefone"
          name="phone"
          rules={[
            {
              pattern: new RegExp(/^[0-9\b]+$/),
              message: "Somente números",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Matérias" name="subjects_id">
          <Select
            mode="multiple"
            placeholder="Selecione as matérias"
            loading={isLoadingSubjects}
            options={dataSubjects}
          />
        </Form.Item>
        <Form.Item
          label="Grupo de faturamento"
          name="prices_groups_id"
          rules={[{ required: true, message: "Campo obrigatório" }]}
        >
          <Select
            placeholder="Selecione o grupo de faturamento"
            loading={isLoadingGroups}
            options={dataGroups}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewTeacherModal;
