import { create } from "zustand";
import React from "react";
import { persist } from 'zustand/middleware'

type themeProps = {
  theme: string
  currentTheme: any
  setTheme: (theme: string) => void
}

const useThemeStore = create(
  persist(
    (set) => ({
      theme: "light",
      currentTheme: {},
      setTheme: (theme: string) => {
        set({ theme });
      },
    }),
    { name: "contemporaneo-theme-cache" },
  ),
);

export default useThemeStore as () => themeProps;
