import { Typography } from "antd";
import React from "react";
import { useNavigate } from 'react-router-dom'
import { HeaderProps } from '../../stores/useTitleStore'
import { PageHeader } from "./styles";

const Header = ({
  title = "Titulo",
  subTitle,
  routes = [],
  isDisabled,
  extra,
  avatar,
  tags,
}: HeaderProps) => {
  const { Text } = Typography;
  const navigate = useNavigate();

  function itemRender(routes) {
    return routes?.map((route, index) => {
      const last = routes?.indexOf(route) === routes.length - 1;

      return last ? (
        <Text key={index}>{route.breadcrumbName}</Text>
      ) : (
        <Text
          key={index}
          type="secondary"
          className={"breadcrumb-link"}
          onClick={() => navigate(route.path)}
        >
          {route.breadcrumbName + " / "}
        </Text>
      );
    });
  }

  return (
    <>
      {!isDisabled && (
        <PageHeader
          className="site-page-header"
          backIcon={false}
          onBack={() => null}
          title={title}
          breadcrumb={{ items : routes }}
          breadcrumbRender={() => itemRender(routes)}
          subTitle={subTitle}
          extra={extra}
          tags={tags}
          avatar={
            avatar?.src
              ? {
                  ...avatar,
                  size: 48,
                }
              : null
          }
        />
      )}
    </>
  );
};

export default Header;
