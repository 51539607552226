import { LockOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Card, Descriptions, Space } from 'antd'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { InlineAvatar } from '~/components/InlineAvatar'
import Logs from '~/components/Teachers/Logs'
import Payments from '~/components/Teachers/Payments'
import Profile from '~/components/Teachers/Profile'
import api from '~/services/axios'
import useContextStore from '~/stores/useContextStore'
 
const ShowTeacherPage = () => {
  const { currentProfile } = useContextStore()

  const { id } = useParams()
  const query = new URLSearchParams(useLocation().search)

  const [currentView, setCurrentView] = useState(query.get('view') || 'profile')

  const { data: teacher } = useQuery(['teacher', id], () =>
    api.get(`/api/teachers/${id}`).then((res) => res.data)
  )

  const tabs = [
    {
      key: 'profile',
      tab: 'Perfil',
      children: <Profile id={id || ''} />,
    },
    {
      key: 'statement',
      tab: 'Extrato',
      children: <Payments key={'statement'} id={id || ''} />,
    },
    {
      key: 'logs',
      tab: <span>{currentProfile !== 'admin' && <LockOutlined />} Logs</span>,
      children: <Logs key={'logs'} id={id || ''} />,
      disabled: currentProfile !== 'admin',
    },
  ]

  const handleTabChange = (key) => {
    if (key === 'logs' && currentProfile !== 'admin') {
      key = 'profile'
    }

    setCurrentView(key)
    query.set('view', key)
    window.history.replaceState({}, '', `${window.location.pathname}?${query}`)
  }

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Card bordered={false}>
        <Descriptions
          title={
            <>
              <InlineAvatar name={teacher?.name} src={teacher?.picture} />{' '}
              {teacher?.name}
            </>
          }
        >
          <Descriptions.Item label="Username">
            {teacher?.username}
          </Descriptions.Item>
          <Descriptions.Item label="Telefone">
            {teacher?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Endereço">
            {teacher?.adress}
          </Descriptions.Item>

          {/* <Descriptions.Item label="Ano"> */}
          {/*  {teacher?.grades */}
          {/*    .filter((grade) => grade.year === new Date().getFullYear()) */}
          {/*    .map((grade) => ( */}
          {/*      <Tag key={grade.type}>{grade.type}</Tag> */}
          {/*    ))} */}
          {/* </Descriptions.Item> */}
        </Descriptions>
      </Card>

      <Card
        tabList={tabs}
        activeTabKey={currentView}
        onTabChange={(key) => {
          handleTabChange(key)
        }}
        styles={{
          body: {
            padding: 8,
          },
        }}
        bordered={false}
      >
        {/* <Row gutter={[8, 8]}>
          {tabs.find((tab) => tab.key === currentView)?.children}
        </Row> */}
      </Card>
    </Space>
  )
}

export default ShowTeacherPage
