import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  notification,
  Radio,
  Rate,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MdMenu } from "react-icons/md";
import { useParams } from "react-router-dom";
import { InlineAvatar } from "~/components/InlineAvatar";
import Loading from "~/components/Loading";
import { MaskDate } from "~/helpers/Masks";
import AddStudent from "~/pages/EventManager/Editor/Components/Modals/AddStudent";
import StatusError from "~/pages/StatusError";
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import useContextStore from "~/stores/useContextStore";
import useTitleStore from "~/stores/useTitleStore";
import ChangeRoom from "./Components/Modals/ChangeRoom";
import ChangeTeacher from "./Components/Modals/ChangeTeacher";
import ChangeTime from "./Components/Modals/ChangeTime";
import PaymentCard from "./Components/PaymentCard";

const EventEditor = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { setIsDisabled } = useTitleStore();
  const [avaliationComplete, setAvaliationComplete] = useState(true);
  const [items, setMenuItems] = useState<MenuProps["items"]>(
    [] as MenuProps["items"],
  );

  const { currentProfile } = useContextStore();
  const { confirm } = Modal;
  const { Panel } = Collapse;

  const { data: me } = useQuery(["me"], () => {
    return api
      .get("/api/user/me")
      .then((res) => res.data)
      .catch((err) => {});
  });

  console.log("me: ", me);

  const {
    data: event,
    isLoading,
    status,
  } = useQuery(["event", id], () =>
    api
      .get(`/api/events/${id}`, {
        params: {
          datailed: true,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message, err.response.data);
      }),
  );

  useEffect(() => {
    const items: MenuProps["items"] = [];

    console.log("currentProfile: ", currentProfile);

    if (
      (currentProfile === "admin" || currentProfile === "secretaria") &&
      (event?.status?.name !== "Cancelado" ||
        event?.status?.name !== "Finalizado")
    ) {
      items.push({
        key: "1",
        disabled:
          event?.status?.name === "Cancelado" ||
          event?.status?.name === "Finalizado",
        label: <ChangeTeacher event={event} />,
      });
      items.push({
        key: "2",
        disabled:
          event?.status?.name === "Cancelado" ||
          event?.status?.name === "Finalizado",
        label: <ChangeTime event={event} />,
      });
      items.push({
        key: "3",
        disabled:
          event?.status?.name === "Cancelado" ||
          event?.status?.name === "Finalizado",
        label: <ChangeRoom event={event} />,
      });
    }

    if (currentProfile === "admin" || currentProfile === "secretaria") {
      items.push({
        key: "4",
        danger: true,
        label: "Cancelar aula",
        disabled: event?.status?.name === "Cancelado",
        onClick: () =>
          confirm({
            title: "Cancelar aula",
            content:
              "Deseja realmente cancelar a aula? Essa ação não poderá ser desfeita e só pode ser realizada se nenhum tipo de pagamento foi realizado.",
            okText: "Confirmar",
            cancelText: "Cancelar",
            okType: "danger",
            onOk() {
              handleCancel();
            },
          }),
      });
    }

    setMenuItems(items);
  }, [event?.status?.name, event?.updated_at, event?.service?.name]);

  useEffect(() => {
    setIsDisabled(true);

    if (!event) {
      return;
    }

    setAvaliationComplete(event.awswers.length);
  }, [event]);

  const { data: forms } = useQuery(["form", "Avaliação pedagógica"], () =>
    api
      .get(`/api/forms/`, {
        params: {
          name: "Avaliação pedagógica",
        },
      })
      .then((res) => res.data[0]),
  );

  const statusUpdate = () => {
    // only call if not called in last 2 seconds
    if (dayjs().diff(dayjs(event?.updated_at), "second") < 5) {
      notification.warning({
        key: "timeout",
        message: "Aguarde 5 segundos para atualizar o status",
        description:
          "Aguarde 5 segundos para atualizar o status novamente, restam " +
          dayjs(event?.updated_at).diff(dayjs(), "seconds") +
          " segundos",
      });
      return;
    }

    api.put(`/api/events/${id}/promote-status`).then((res) => {
      queryClient.invalidateQueries(["event", id]);
    });
  };

  const handleCancel = () => {
    api
      .put(`/api/events/${id}/cancel`)
      .then((res) => {
        queryClient.invalidateQueries(["event", id]);
        notification.destroy("warning");
        notification.success({
          message: "Aula cancelado com sucesso!",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Erro ao cancelar aula!",
          description: err.response.data.message,
        });
      });
  };

  const customIcons: Record<number, React.ReactNode> = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <MehOutlined />,
    6: <MehOutlined />,
    7: <MehOutlined />,
    8: <SmileOutlined />,
    9: <SmileOutlined />,
    10: <SmileOutlined />,
  };

  if (status === "error") {
    return <StatusError code={403} />;
  }

  if (isLoading) return <Loading />;

  return (
    <Card>
      <Descriptions
        title={<Typography.Title level={4}>Detalhes da aula</Typography.Title>}
        extra={
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space align="start">
                Ações
                <MdMenu size={24} />
              </Space>
            </a>
          </Dropdown>
        }
        column={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5 }}
      >
        <Descriptions.Item label="Data">
          {MaskDate(event?.date)}
        </Descriptions.Item>

        <Descriptions.Item label="Inicio">{event?.int_time}</Descriptions.Item>
        <Descriptions.Item label="Fim">{event?.end_time}</Descriptions.Item>

        <Descriptions.Item label="Tipo">
          {event?.service?.name}
        </Descriptions.Item>

        <Descriptions.Item label="Local">
          <Tag color="blue" title={event?.modality?.description}>
            {event?.modality?.name}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Sala">
          <Tag color={event?.room?.color} title={event?.room?.name}>
            {event?.room?.name}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Turma">
          <Tag color={event?.grade?.color} title={event?.grade?.type}>
            {event?.grade?.type}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Professor">
          {event?.teachers[0]?.name}
        </Descriptions.Item>

        <Descriptions.Item label="Matéria">
          <Tag color={event?.subject?.color} title={event?.subject?.name}>
            {event?.subject?.name}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Stauts">
          <Tag color={event?.status?.color} title={event?.status?.description}>
            {event?.status?.name}
          </Tag>
        </Descriptions.Item>
      </Descriptions>

      {(currentProfile === "admin" || currentProfile === "secretaria") && (
        <>
          <br />
          <Typography.Title level={5}>Alunos</Typography.Title>

          <Row gutter={[16, 16]}>
            {event?.payments_students?.map((payment) => (
              <Col key={payment.id} xs={24} sm={24} md={12} lg={8} xl={6}>
                <PaymentCard
                  payment={payment}
                  type={"student"}
                  eventId={event?.id}
                />
              </Col>
            ))}

            <AddStudent event={event} />
          </Row>

          <br />
          <Typography.Title level={5}>Professor</Typography.Title>
          <Row gutter={[16, 16]}>
            {event?.payments_teachers?.map((payment) => (
              <Col key={payment.id} xs={24} sm={24} md={12} lg={8} xl={6}>
                <PaymentCard
                  payment={payment}
                  type={"teacher"}
                  eventId={event?.id}
                />
              </Col>
            ))}
          </Row>
        </>
      )}

      {(((currentProfile === "admin" ||
        (currentProfile === "professores" &&
          event.teachers
            .map((teacher) => teacher.id)
            .includes(me?.teacher_id))) &&
        event?.status?.name === "Avaliação: Pendente") ||
        ((currentProfile === "admin" ||
          currentProfile === "professores" ||
          currentProfile === "secretaria") &&
          event?.status?.name === "Finalizado" && event?.service?.name !== "Consulta")) && (
        <>
          <br />
          <h4>Avaliação</h4>

          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              api
                .post(`/api/events/${id}/avaliate`, { avaliation: values })
                .then((res) => {
                  queryClient.invalidateQueries(["event", id]);
                  notification.success({
                    message: "Avaliação realizada com sucesso",
                  });
                })
                .catch((err) => {
                  queryClient.invalidateQueries(["event", id]);
                  notification.error({
                    message: "Erro ao enviar avaliação, tente novamente",
                  });
                });
            }}
            onSubmitCapture={(values) => {
              console.log("onSubmitCapture values: ", values);
            }}
          >
            <Collapse
              defaultActiveKey={event?.students?.map((item) => item.id)}
              onChange={(key) => {
                console.log("key: ", key);
              }}
              collapsible="disabled"
            >
              {event?.students?.map((student) => (
                <Panel
                  key={student.id}
                  header={
                    <>
                      <InlineAvatar
                        name={student?.user?.name}
                        src={student?.user?.picture}
                      />{" "}
                      <span>{student?.user?.name}</span>
                    </>
                  }
                >
                  <Row gutter={[16, 16]}>
                    {forms?.questions
                      ?.sort((a, b) => a.order - b.order)
                      ?.map((question, index) => (
                        <Col
                          key={question.id}
                          span={question.type === "textarea" ? 24 : 12}
                        >
                          {question.type === "text" && (
                            <Form.Item
                              key={question.id}
                              label={question.question}
                              name={[student.id, question.id]}
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obrigatório",
                                },
                              ]}
                            >
                              <Input
                                defaultValue={
                                  event?.awswers?.find(
                                    (item) =>
                                      item.related_id === student.id &&
                                      item.question_id === question.id,
                                  )?.answer
                                }
                                disabled={event?.status?.name === "Finalizado"}
                              />
                            </Form.Item>
                          )}

                          {question.type === "range-0-10" && (
                            <Form.Item
                              key={question.id}
                              label={question.question}
                              name={[student.id, question.id]}
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obrigatório",
                                },
                              ]}
                            >
                              <Rate
                                disabled={event?.status?.name === "Finalizado"}
                                count={10}
                                defaultValue={
                                  event?.awswers?.find(
                                    (item) =>
                                      item.related_id === student.id &&
                                      item.question_id === question.id,
                                  )?.answer || 0
                                }
                                tooltips={[
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                  "10",
                                ]}
                                style={{
                                  color: "#2f7146",
                                }}
                                character={({ index }: { index: number }) =>
                                  customIcons[index + 1]
                                }
                              />
                            </Form.Item>
                          )}

                          {question.type === "yes/no" && (
                            <Form.Item
                              key={question.id}
                              label={question.question}
                              name={[student.id, question.id]}
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obrigatório",
                                },
                              ]}
                            >
                              <Radio.Group
                                key={question.id}
                                defaultValue={
                                  event?.awswers?.find(
                                    (item) =>
                                      item.related_id === student.id &&
                                      item.question_id === question.id,
                                  )?.answer
                                }
                                disabled={event?.status?.name === "Finalizado"}
                              >
                                <Radio value={"Sim"}>Sim</Radio>
                                <Radio value={"Não"}>Não</Radio>
                              </Radio.Group>
                            </Form.Item>
                          )}

                          {question.type === "textarea" && (
                            <Form.Item
                              key={question.id}
                              label={question.question}
                              name={[student.id, question.id]}
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obrigatório",
                                },
                              ]}
                            >
                              <Input.TextArea
                                defaultValue={
                                  event?.awswers?.find(
                                    (item) =>
                                      item.related_id === student.id &&
                                      item.question_id === question.id,
                                  )?.answer
                                }
                                disabled={event?.status?.name === "Finalizado"}
                              />
                            </Form.Item>
                          )}
                        </Col>
                      ))}
                  </Row>
                </Panel>
              ))}
            </Collapse>

            {event?.status?.name === "Avaliação: Pendente" && (
              <Row gutter={[16, 16]}>
                <Col span={24} style={{ textAlign: "right", marginTop: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Enviar avaliação
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: "right", marginTop: 16 }}>
          {event?.status?.name === "Solicitado" && (
            <Button
              type="primary"
              onClick={() =>
                notification.warning({
                  key: "warning",
                  message: "Confirmar disponibilidade",
                  description:
                    currentProfile === "professores"
                      ? "Ao confirmar sua disponibilidade, você estará se comprometendo a realizar a aula no dia e horário agendado."
                      : "Deseja confirmar a disponibilidade do professor e agendar a aula?",
                  btn: (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        statusUpdate();
                        notification.destroy();
                      }}
                    >
                      Confirmar
                    </Button>
                  ),
                })
              }
            >
              Confirmar disponibilidade
            </Button>
          )}

          {event?.status?.name === "Confirmado" && (
            <Button
              type="primary"
              disabled={dayjs().isBefore(
                dayjs(`${event?.date} ${event?.end_time}`).subtract(
                  5,
                  "minute",
                ),
                "minute",
              )}
              onClick={() =>
                notification.warning({
                  key: "warning",
                  message: "Aula aplicada",
                  description:
                    currentProfile === "professores"
                      ? "Ao confirmar a aula aplicada, você confirma a realização da aula e presença do aluno, caso o aluno não tenha realizado a aula, não confirme essa etapa e comunique a secretaria. Não esqueça de preencher a avaliação do aluno no próximo passo."
                      : "Deseja confirmar a realização da aula?",
                  btn: (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        statusUpdate();
                        notification.destroy("warning");
                      }}
                    >
                      Confirmar
                    </Button>
                  ),
                })
              }
            >
              Aula concluída
            </Button>
          )}
        </Col>
      </Row>

      <br />
      <div>
        Última atualização{" "}
        {!isLoading &&
          new Intl.DateTimeFormat("pt-BR", {
            dateStyle: "full",
            timeStyle: "full",
          })?.format(new Date(event?.updated_at))}
      </div>
    </Card>
  );
};

export default EventEditor;
