import React, { useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from '@tanstack/react-query'
import { Dropdown, Modal, Skeleton, Space, Typography } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { BsCloudMoonFill, BsFillSunFill } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { IoLogOutOutline } from "react-icons/io5";
import { InlineAvatar } from "~/components/InlineAvatar";
import api from "~/services/axios";
import useThemeStore from "~/stores/useThemeStore";

interface AvatarDropdownProps {
  menu?: boolean;
  user?: any;
}

const AvatarDropdown = ({ menu, user }: AvatarDropdownProps) => {
  const { keycloak } = useKeycloak();
  const { theme, setTheme } = useThemeStore();
  const [isHover, setIsHover] = useState(false);
  const { Text } = Typography;

  const { data: me, isLoading } = useQuery(["me"], () => {
    return api
      .get("/api/user/me")
      .then((res) => res.data)
      .catch((_err) => {});
  });

  const menuItems = [
    {
      key: "center",
      icon: <AiOutlineUser />,
      label: "Meu Perfil",
      disabled: true,
    },
    {
      key: "settings",
      icon: <CiSettings />,
      label: "Configurações",
      disabled: true,
    },
    {
      key: "darkmode",
      icon: theme === "dark" ? <BsFillSunFill /> : <BsCloudMoonFill />,
      label: theme === "dark" ? "Modo Claro" : "Modo Escuro",
      disabled: false,
      onClick: () => setTheme(theme === "dark" ? "light" : "dark"),
    },
    {
      type: "divider" as const,
    },
    {
      key: "logout",
      icon: <IoLogOutOutline />,
      label: "Desconectar",
      onClick: () => {
        Modal.confirm({
          title: "Confirmar saída?",
          content: "Deseja realmente sair da aplicação?",
          okText: "Sair",
          cancelText: "Cancelar",
          onOk: () => {
            keycloak.logout();
          },
          onCancel: () => {},
        });
      },
    },
  ];

  return (
    <Dropdown
      menu={{
        selectedKeys: [],
        onClick: () => {},
        items: menuItems,
      }}
      trigger={["click"]}
    >
      <Text onClick={(e) => e.preventDefault()}>
        <Space
          style={{
            cursor: "pointer",
            backgroundColor: isHover ? "rgba(208,208,208,0.06)" : "transparent",
            padding: "8px 8px",
            borderRadius: "4px",
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isLoading ? (
            <>
              <Skeleton.Avatar active shape="circle" size={32} />
              <Skeleton.Input style={{ width: 100 }} active />
            </>
          ) : (
            <>
              <InlineAvatar src={me?.picture} name={me?.name} />
              {theme === "dark" ? (
                <Text strong>{me?.name}</Text>
              ) : (
                <strong style={{ color: "rgb(241,243,246)" }}>
                  {me?.name}
                </strong>
              )}
            </>
          )}
        </Space>
      </Text>
    </Dropdown>
  );
};

export default AvatarDropdown;
