import React from "react";

import { keycloak, keycloakConfig } from "./services/Keycloak/Keycloak";
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { AppRouter } from "./routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./services/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, notification } from "antd";

import ptBR from "antd/es/locale/pt_BR";
import useThemeStore from "./stores/useThemeStore";
import dayjs from "dayjs";
import themeLight, { themeDark } from "./styles/tokens";

function App() {
  const { theme } = useThemeStore();
  dayjs.locale("pt-br");

  notification.config({
    duration: 10,
  });

  return (
    <div>
      <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakConfig}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={theme === "dark" ? themeDark : themeLight}
            direction="ltr"
            locale={ptBR}
          >
            <AppRouter />
          </ConfigProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
