import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import { InlineAvatar } from '~/components/InlineAvatar'
import colors from "~/styles/colors";
import { MaskMoney } from "~/helpers/Masks";
import {
  Button,
  Card,
  Descriptions,
  notification,
  Tag,
  Typography,
} from "antd";
import React from "react";
import { DeleteOutlined } from "@ant-design/icons";

interface PaymentCardProps {
  payment: any;
  type: "student" | "teacher";
  eventId: "string";
}
const PaymentCard = ({ payment, type, eventId }: PaymentCardProps) => {
  const user = type === "student" ? payment?.student : payment?.teacher;

  const removeStudent = () => {
    api
      .put(`/api/events/${eventId}/remove-student`, {
        student_id: payment?.student?.id,
      })
      .then((res) => {
        notification.success({
          message: "Aluno removido com sucesso!",
        });
        notification.destroy("remove-student");
        queryClient.invalidateQueries(["event", eventId]);
      })
      .catch((err) => {
        notification.error({
          message: "Erro",
          description: err.response.data.message,
        });
      });
  };

  return (
    <Card>
      <Descriptions title="" column={2}>
        <Descriptions.Item
          label=""
          span={2}
          contentStyle={{ display: "flex", alignItems: "center" }}
        >
          <InlineAvatar name={user?.name} src={user?.picture} />
          <Typography.Text strong style={{ marginLeft: 8 }}>
            {user?.name}
          </Typography.Text>
          {type === "student" && payment.status.name !== "Pago" && (
            <Button
              type={"link"}
              style={{ marginLeft: "auto" }}
              onClick={() =>
                notification.warning({
                  key: "remove-student",
                  message: "Tem certeza que deseja remover o aluno?",
                  description: "Esta ação não pode ser desfeita",
                  btn: (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        removeStudent();
                        notification.destroy("warning");
                      }}
                    >
                      Confirmar
                    </Button>
                  ),
                })
              }
            >
              <DeleteOutlined
                style={{
                  color: colors.status.error,
                }}
              />
            </Button>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Valor">
          {MaskMoney(payment?.amount)}
        </Descriptions.Item>

        <Descriptions.Item label="Status">
          <Tag
            color={payment?.status?.color}
            title={payment?.status?.description}
            style={{ marginLeft: "auto" }}
          >
            {payment?.status?.name}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default PaymentCard;
