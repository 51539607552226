import type { ProTokenType } from "@ant-design/pro-provider";
import { theme } from "antd";
import { ThemeConfig } from 'antd/es/config-provider/context'

const { darkAlgorithm, defaultAlgorithm } = theme;

export const themeLight = {
  token: {
    colorPrimary: "#389e0d",
    colorBgBase: "#fefefe",
    colorTextBase: "#1c2028",
    colorLink: "#07a451",
    colorLinkHover: "#54ab29",
    colorLinkActive: "#237804",
  },
  components: {
    Layout: {
      headerBg: "#1c5836",
      bodyBg: "#efefef",
    },
  },
} as ThemeConfig;

export const themeDark = {
  token: {
    colorPrimary: "#49d58d",
    colorBgBase: "#1B2028",
    colorTextBase: "#fefefe",
    colorLink: "#2CC174",
    colorLinkHover: "#76ad5a",
    colorLinkActive: "#63b247",
    colorInfo: "#1890FF",
    colorSuccess: "#2CC174",
    colorWarning: "#F5A623",
    colorError: "#F5222D",
  },
  components: {
    Alert: {
      colorInfoBg: "#1890FF",
      colorInfoBorder: "#1890FF",
      colorInfoText: "#31353F",
      colorTextInfo: "#31353F",

      colorSuccessBg: "#2CC174",
      colorSuccessBorder: "#2CC174",
      colorSuccessText: "#31353F",
      colorTextSuccess: "#31353F",

      colorWarningBg: "#F5A623",
      colorWarningBorder: "#F5A623",
      colorWarningText: "#31353F",
      colorTextWarning: "#31353F",

      colorErrorBg: "#F5222D",
      colorErrorBorder: "#F5222D",
      colorErrorText: "#31353F",
      colorTextError: "#31353F",
    },
    Tooltip: {
      colorBg: "#31353F",
    },
    Modal: {
      colorBg: "#31353F",
      colorText: "#fefefe",
      colorFillContent: "#31353F",
    },
  },
} as ThemeConfig;

export const proThemeLight = {
  colorTextBase: "#32325D",
  bgLayout: "#F5F7FA",

  colorPrimary: "#389e0d",
  colorTextSecondary: "#737B8E",
  colorTextPlaceholder: "#bfbfbf",
  
  sider: {
    colorBgMenuItemSelected: "#288d53",
    colorTextMenuSelected: "#fefeff",
    colorTextMenuItemHover: "#a19e9e",
    colorBgCollapsedButton: "#288d53",
    colorTextCollapsedButton: "#fefefe",
    colorBgCollapsedButtonHover: "#288d53",
    colorTextCollapsedButtonHover: "#fefefe",
    colorTextSubMenuSelected: "#fefefe",
  },
  header: {
    colorBgMenuItemSelected: "#f3f5f8",
    colorTextMenuSelected: "#1c5836",
    colorHeaderTitle: "#fcfcfc",

    colorTextMenu: "#fafafa",
    colorTextMenuItemHover: "#fefefe",
    colorBgMenuActive: "#f3f5f8",
    colorTextMenuActive: "#cbc9c9",
    colorBgHeader: "#1c5836",
    colorTextMenuSecondary: "#1c5836",
    
  },

} as ProTokenType["layout"];

export const proThemeDark = {
  bgLayout: "#31353F",
  colorPrimary: "#2CC174",
  colorTextBase: "#fefefe",
  colorTextSecondary: "#bfbfbf",
  colorTextPlaceholder: "#bfbfbf",
  colorTextDisabled: "#bfbfbf",
  colorTextCaption: "#bfbfbf",
  colorTextParagraph: "#bfbfbf",

  pageContainer: {
    colorBgPageContainer: "#31353F",
  },
  header: {
    colorBgHeader: "#1B2028",
    colorHeaderTitle: "#fefefe",

    colorTextMenu: "#fefefe",
    colorTextMenuActive: "#fefefe",
    colorTextMenuHover: "#fefefe",
    colorTextMenuItemHover: "#fefefe",
    colorBgMenuItemActive: "#31353F",

    colorTextMenuItemSelected: "#fefefe",
    colorBgMenuItemHover: "#2CC174",
    colorBgMenuItemSelected: "#2CC174",
    colorBgMenuItemCollapsedElevated: "#2CC174",
  },
  sider: {
    colorMenuBackground: "#1B2028",
    colorBgMenuHover: "#2CC174",

    colorTextMenu: "#fefefe",
    colorTextMenuActive: "#fefefe",
    colorTextMenuHover: "#fefefe",
    colorTextMenuSelected: "#2CC174",
    colorTextMenuItemHover: "#2CC174",
    colorBgMenuItemHover: "#2CC174",
    colorBgMenuItemSelected: "#31353F",
    colorBgMenuItemActive: "#31353F",

    colorTextCollapsedButton: "#fefefe",
    colorTextCollapsedButtonHover: "#fefefe",
    colorTextCollapsedButtonActive: "#fefefe",
    colorTextCollapsedButtonSelected: "#fefefe",

    colorBgCollapsedButton: "#2CC174",
    colorBgCollapsedButtonHover: "#2CC174",
    colorBgCollapsedButtonActive: "#2CC174",

    colorMenuItemDivider: "#2CC174",

    colorBgMenuItemCollapsedHover: "#2CC174",
    colorBgMenuItemCollapsedSelected: "#2CC174",
    colorBgMenuItemCollapsedActive: "#2CC174",
    colorBgMenuItemCollapsed: "#1E1F1F",

    colorTextMenuSecondary: "#bfbfbf",
    colorTextMenuTitle: "#fefefe",
    colorTextSubMenuSelected: "#fefefe",
  },
  tooltip: {
    colorBg: "#31353F",
    colorText: "#fefefe",
  },
} as ProTokenType["layout"];

export default themeLight;