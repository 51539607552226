import { Col, Form, Modal, notification, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from 'react'
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import { useQuery } from "@tanstack/react-query";

const ChangeRoom = ({ event }) => {
  const [form] = Form.useForm();
  const [modalChangeRoom, setModalChangeRoom] = useState(false);

  const [filteredRooms, setFilteredRooms] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      modality: event?.modality?.id,
      room: event?.room?.name,
    });
  }, [event, event?.room?.id, event?.modality?.id]);

  const { data: availableModalities, isLoading: isLoadingModalities } =
    useQuery(
      ["modalitiesListSelect"],
      () =>
        api.get("/api/modalities").then((res) => {
          const select = res.data.map((modality) => ({
            label: modality.name,
            value: modality.id,
          }));

          return select;
        }),
      {
        refetchOnWindowFocus: false,
      },
    );

  const { data: availableRooms, isLoading: isLoadingRooms } = useQuery(
    ["roomsListSelect"],
    () =>
      api.get("/api/rooms").then((res) => {
        const select = res.data.map((room) => ({
          label: room.name,
          value: room.id,
          modality_id: room.modality_id,
          capacity: room.capacity,
        }));

        const currentModality = availableModalities?.find(
          (modality) => modality.value === event?.modality?.id,
        );
        const filtered = select
          ?.filter((room) => room.modality_id === currentModality?.value)
          ?.sort((a, b) => (a.label > b.label ? 1 : -1));

        setFilteredRooms(filtered);

        const currentRoom =
          filtered?.find((room) => room.value === event?.room?.id) ||
          filtered[0];
        console.log(currentRoom, currentModality);
        form.setFieldsValue({
          modality: currentModality?.value,
          room: currentRoom?.value,
        });

        return select;
      }),
    {
      enabled: !!availableModalities,
      refetchOnWindowFocus: false,
    },
  );

  const onConfirm = () => {
    const params = {
      room_id: form.getFieldsValue().room,
    };

    // check if values are different
    if (params.room_id === event.room.id) {
      notification.warning({
        message: "Atenção",
        description: "Aula já está nesta modalidade/local",
      });
      return;
    }

    api
      .put(`/api/events/${event?.id}/new-room`, params)
      .then((res) => {
        notification.success({
          message: "Sucesso",
          description: "Modalidade/local alterados com sucesso",
        });
        queryClient.invalidateQueries(["event", event.id]);
        setModalChangeRoom(false);
      })
      .catch((err) => {
        notification.error({
          message: "Erro",
          description: err.response.data.message,
        });
      });
  };

  const filterRooms = useCallback(
    (modalityId) => {
      if (!availableRooms) return;

      //filter rooms by modality and order a-z
      const filtered = availableRooms
        ?.filter((room) => room.modality_id === modalityId)
        ?.sort((a, b) => (a.label > b.label ? 1 : -1));

      setFilteredRooms(filtered);
      form.setFieldsValue({
        room: filtered[0]?.value,
      });
    },
    [availableRooms],
  );

  return (
    <>
      <a onClick={() => setModalChangeRoom(true)}>Mudar Modalidade/Local</a>

      <Modal
        title="Mudar modalidade/local da aula"
        open={modalChangeRoom}
        onOk={() => onConfirm()}
        onCancel={() => setModalChangeRoom(false)}
      >
        <div>
          <p>Tem certeza que deseja trocar a modalidade/local da aula?</p>

          <Row gutter={[8, 8]}>
            <Col span={12}>
              <strong>Modalidade:</strong> {event?.modality?.name}
            </Col>
            <Col span={12}>
              <strong>Local:</strong> {event?.room?.name}
            </Col>

            <Col span={24}>
              <Form form={form} layout="vertical">
                <Form.Item
                  key={"modality"}
                  name={"modality"}
                  label={"Modalidade"}
                >
                  <Select
                    loading={isLoadingModalities}
                    options={availableModalities}
                    onChange={(value) => {
                      filterRooms(value);
                    }}
                  />
                </Form.Item>
                <Form.Item key={"room"} name={"room"} label={"Local"}>
                  <Select loading={isLoadingRooms} options={filteredRooms} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ChangeRoom;
