import { useQuery } from "@tanstack/react-query";
import { Card, Col, Form, Modal, notification, Select } from 'antd'
import React, { useState } from 'react'
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import colors from "~/styles/colors";

import { PlusOutlined } from "@ant-design/icons";

const AddStudent = ({ event }) => {
  const [form] = Form.useForm();
  const [modalAddStudent, setModalAddStudent] = useState(false);

  // current grade id from event
  const gradeId = event?.grade?.id;

  // get students
  const { data: students, isLoading: isLoadingStudents } = useQuery(
    ["studentsListSelect", gradeId],
    () => {
      return api
        .get("/api/students", {
          params: {
            grade: gradeId,
          },
        })
        .then((res) => {
          const select = res.data.map((student) => ({
            label: student.name,
            value: student.id,
          }));

          form.setFieldsValue({
            student_id: select[0].value,
          });

          return select;
        });
    },
    {
      enabled: !!gradeId,
      refetchOnWindowFocus: false,
    },
  );

  const onConfirm = () => {
    const params = {
      student_id: form.getFieldsValue().student_id,
    };
    api
      .put(`/api/events/${event?.id}/add-student`, params)
      .then((res) => {
        notification.success({
          message: "Sucesso",
          description: "Aluno adicionado com sucesso!",
        });
        queryClient.invalidateQueries(["event", event.id]);
        setModalAddStudent(false);
      })
      .catch((err) => {
        notification.error({
          message: "Erro",
          description: err.response.data.message,
        });
      });
  };

  return (
    <>
      {event?.status?.name !== "Finalizado" &&
        event?.status?.name !== "Cancelado" && (
          <Col key={"add_student"} xs={12} sm={12} md={12} lg={8} xl={6}>
            <Card
              style={{ height: "100%" }}
              styles={{
                body: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                },
              }}
              hoverable
              onClick={() => {
                setModalAddStudent(true);
              }}
            >
              <PlusOutlined
                style={{
                  fontSize: 64,
                  color: colors.primary._000,
                }}
              />
            </Card>
          </Col>
        )}

      <Modal
        title="Adicionar aluno"
        open={modalAddStudent}
        onOk={() => onConfirm()}
        onCancel={() => setModalAddStudent(false)}
      >
        <div>
          <p>Selecione um aluno para adicionar a aula.</p>

          <Form form={form} layout="vertical">
            <Form.Item
              label="Aluno"
              name="student_id"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Selecione um aluno"
                loading={isLoadingStudents}
                showSearch
                options={students}
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddStudent;
