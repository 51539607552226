import { useQuery } from "@tanstack/react-query";
import { Button, Form, Modal, notification, Select } from 'antd'
import React, { useState } from 'react'
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";

const ChangeTeacher = ({ event }) => {
  const [form] = Form.useForm();

  const watchSubject = Form.useWatch("subject_id", form);

  const [modalChangeTeacher, setModalChangeTeacher] = useState(false);

  // get subjects
  const { data: subjects, isLoading: isLoadingSubjects } = useQuery(
    ["subjectsListSelect"],
    () => {
      return api.get("/api/subjects").then((res) => {
        const select = res.data.map((subject) => ({
          label: subject.name,
          value: subject.id,
        }));

        form.setFieldsValue({
          subject_id: select[0].value,
        });

        return select;
      });
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  //get services
  const { data: availableServices, isLoading: isLoadingServices } = useQuery(
    ["servicesListSelect"],
    () =>
      api.get("/api/services").then((res) => {
        const select = res.data.map((service) => ({
          label: service.name,
          value: service.id,
        }));

        form.setFieldsValue({
          type: [select[0].value],
        });

        return select;
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // get teachers
  const { data: teachers, isLoading: isLoadingTeachers } = useQuery(
    ["teachersListSelect", watchSubject],
    () => {
      return api.get("/api/teachers").then((res) => {
        const select = res.data
          .filter((teacher) =>
            teacher.subjects.some((subject) => subject.id === watchSubject),
          )
          .map((teacher) => ({
            label: teacher.name,
            value: teacher.id,
          }));

        form.setFieldsValue({
          teacher_id: select[0].value,
        });

        return select;
      });
    },
    {
      enabled: !!watchSubject,
      refetchOnWindowFocus: false,
    },
  );

  const onConfirm = () => {
    const params = {
      event_id: event.id,
      subject_id: form.getFieldsValue().subject_id,
      teacher_id: form.getFieldsValue().teacher_id,
      service_id: form.getFieldsValue().service_id,
    };
    api
      .put(`/api/events/update-teachers`, params)
      .then((res) => {
        notification.success({
          message: "Sucesso",
          description: "Professor trocado com sucesso",
        });
        queryClient.invalidateQueries(["event", event.id]);
        setModalChangeTeacher(false);
      })
      .catch((err) => {
        notification.error({
          message: "Erro",
          description: err.response.data.message,
        });
      });
  };

  return (
    <>
      <a onClick={() => setModalChangeTeacher(true)}>Trocar professor</a>

      <Modal
        title="Trocar professor"
        open={modalChangeTeacher}
        onOk={() => onConfirm()}
        onCancel={() => setModalChangeTeacher(false)}
      >
        <div>
          <p>Tem certeza que deseja trocar o professor da aula?</p>
          <p>
            <strong>Tipo de aula atual:</strong> {event?.service?.name}
          </p>
          <p>
            <strong>Materia atual:</strong> {event?.subject?.name}
          </p>
          <p>
            <strong>Professor atual:</strong> {event?.teachers[0]?.name}
          </p>

          <Form form={form} layout="vertical">
            <Form.Item
              key={"service_id"}
              name={"service_id"}
              label={"Tipo de aula"}
              initialValue={event?.service?.id}
            >
              <Select
                placeholder="Selecione um tipo de aula"
                loading={isLoadingServices}
                options={availableServices}
                defaultValue={event?.service?.name}
              />
            </Form.Item>

            <Form.Item
              label="Nova materia"
              name="subject_id"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Selecione uma materia"
                loading={isLoadingSubjects}
                showSearch
                options={subjects}
                defaultValue={event?.subject?.[0]?.id}
              />
            </Form.Item>
            <Form.Item
              label="Novo professor"
              name="teacher_id"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Selecione um professor"
                loading={isLoadingTeachers}
                showSearch
                options={teachers}
                defaultValue={event?.teachers?.[0]?.id}
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ChangeTeacher;
