import React from "react";

import { LoadingScreen } from "./styles";

const Loading = ({ ...props }) => {
  return (
    <LoadingScreen {...props}>
      <div className='building-blocks'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingScreen>
  )
}

export default Loading;
