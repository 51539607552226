import { Card, Checkbox, Space } from "antd";
import React, { useEffect } from "react";
import useTitleStore from '../../../stores/useTitleStore'
import { ColumnsType } from "antd/es/table";
import api from "../../../services/axios";
import { useQuery } from "@tanstack/react-query";
import Table from "../../../components/Table";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const profilesColors = {
  admin: "red",
  secretaria: "blue",
  professores: "green",
  alunos: "purple",
};

const Rooms = () => {
  const { setTitle } = useTitleStore();

  const { isLoading, data } = useQuery(["listAllRooms"], () => {
    return api
      .get("/api/rooms")
      .then((res) => res.data)
      .catch((err) => {});
  });

  useEffect(() => {
    setTitle({
      title: "Salas",
      subTitle: "Gerenciar salas",
      routes: [
        { path: "/portal/admin", breadcrumbName: "Administrativo" },
        {
          path: "/portal/admin/rooms",
          breadcrumbName: "Salas",
        },
      ],
    });
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, item) => (
        <Space
          style={{ borderLeft: `4px solid ${item?.color}`, paddingLeft: "8px" }}
        >
          <span>{item?.name}</span>
        </Space>
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Capacidade",
      dataIndex: "capacity",
      key: "capacity",
      width: "150px",
    },
    {
      title: "Cor",
      dataIndex: "color",
      key: "color",
      width: 150,
      render: (_, item) => (
        <Space>
          <span
            style={{
              minHeight: "16px",
              minWidth: "16px",
              display: "block",
              background: item?.color,
            }}
          ></span>
          <span>{item?.color?.toUpperCase()}</span>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      width: "100px",
      render: (text) => (
        <Space>
          <Checkbox checked={text} />
        </Space>
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: "60px",
    },
  ];

  return (
    <Space direction={"vertical"} size={16} style={{ display: "flex" }}>
      {/* <Card></Card> */}
      <Card title="Listagem de Salas" extra={<a href="#">+ Adicionar</a>}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          size={"small"}
        />
      </Card>
    </Space>
  );
};

export default Rooms;
