import { useQuery } from "@tanstack/react-query";
import { Dropdown, notification, Space, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from "react";
import { MdMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { InlineAvatar } from "~/components/InlineAvatar";
import Link from "~/components/Link";
import Table from "~/components/Table";
import { generateFilter, onFilter } from "~/helpers/utils";
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import colors from "~/styles/colors";

interface DataType {
  key: React.Key;
  id: string;
  name: string;
  picture: string;
  user_id: string;
  active: boolean;
  subjects: Array<{
    id: string;
    name: string;
    color: string;
  }> | null;
  user: {
    id: string;
    name: string;
    email: string;
    picture: string;
    phone: string;
  };
}

const ListAllTeachers = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useQuery(["listAllTeachers"], () => {
    return api
      .get("/api/teachers")
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
      });
  });

  const handleStatusChange = (status: boolean, id: string) => {
    api
      .patch(`/api/teachers/${id}/status`, {
        status,
      })
      .then(async (response) => {
        const teacher = response.data;
        await queryClient.invalidateQueries(["listAllTeachers"]);

        notification.destroy("success-status-change");
        notification.success({
          key: "success-status-change",
          message: `Professor ${teacher?.name}, ${
            status ? "ativado" : "desativado"
          } com sucesso`,
        });
      })
      .catch((err) => {
        console.log(err);
        notification.destroy("error-status-change");
        notification.error({
          key: "error-status-change",
          message: `Erro ao ${status ? "ativar" : "desativar"} professor`,
          description: "Tente novamente mais tarde",
        });
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      filters: generateFilter(data || [], "name"),
      onFilter: (value, record) => onFilter(value, "name", record),
      filterSearch: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => b.name.localeCompare(a.name),
      render: (_, item) => (
        <Link to={`/portal/teachers/${item.id}?view=profile`}>
          <InlineAvatar name={item.name} src={item.picture} />
          {item.name}
        </Link>
      ),
    },
    {
      title: "E-mail",
      dataIndex: ["email"],
      key: "email",
    },
    {
      title: "Telefone",
      dataIndex: ["phone"],
      key: "phone",
    },
    {
      title: "Matérias",
      dataIndex: "subjects",
      key: "subjects",
      filters: generateFilter(
        data?.map((user) => user.subjects).flat(),
        "name",
      ),
      onFilter: (value, record) =>
        onFilter(value, ["subjects", "name"], record),
      filterSearch: true,
      render: (_, item) => (
        <Space>
          {item?.subjects?.map((subject) => (
            <Tag key={subject.name} color={subject.color}>
              {subject.name}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      sorter: (a, b) => (a.active ? 1 : -1),
      key: "active",
      filters: [
        {
          text: "Ativo",
          value: true,
        },
        {
          text: "Inativo",
          value: false,
        },
      ],
      onFilter: (value, record) => record.active === value,
      render: (_, item) => (
        <Tag color={item.active ? colors.status.success : colors.status.error}>
          {item.active ? "Ativo" : "Inativo"}
        </Tag>
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: "30px",
      render: (_, item) => (
        <Dropdown
          menu={{
            items: [
              {
                label: "Perfil",
                onClick: () =>
                  navigate(`/portal/teachers/${item.id}?view=profile`),
                key: "profile",
              },
              {
                label: "Ver extrato",
                onClick: () =>
                  navigate(`/portal/teachers/${item.id}?view=statement`),
                key: "statement",
              },
              {
                key: "status",
                label: item.active ? "Desativar" : "Ativar",
                onClick: () => handleStatusChange(!item.active, item.id),
              },
            ],
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MdMoreVert />
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      loading={isLoading}
      columns={columns}
      dataSource={data}
      size={"small"}
    />
  );
};

export default ListAllTeachers;
