import { useQuery } from "@tanstack/react-query";
import { Col, Form, Typography } from "antd";
import React from 'react'
import api from "~/services/axios";
import useContextStore from "~/stores/useContextStore";

interface ProfileProps {
  id: string;
}

const Profile = ({ id }: ProfileProps) => {
  const [form] = Form.useForm();
  const { currentProfile, setCurrentProfile } = useContextStore();

  const { Text, Title } = Typography;

  const { data: teacher, isLoading: isLoadingTeacher } = useQuery(
    ["teacher", id],
    () =>
      api.get(`/api/teachers/${id}`).then((res) => {
        return res.data;
      }),
    {
      // enabled: currentProfile === 'admin',
    },
  );

  return <Col span={24} style={{ maxWidth: "990px", margin: "0 auto" }}></Col>;
};

export default Profile;
