import { Card, Input, Space, Tag } from "antd";
import React, { useEffect } from "react";
import useTitleStore from '../../stores/useTitleStore'
import Table from "~/components/Table";
import api from "~/services/axios";
import { useQuery } from "@tanstack/react-query";
import { MaskDate, MaskMoney } from "~/helpers/Masks";

const Prices = () => {
  const { setTitle } = useTitleStore();

  const [duration, setDuration] = React.useState(1);
  useEffect(() => {
    setTitle({
      title: "Orçamentos",
      subTitle: "Consultar tabela de preços",
    });
  }, []);

  const { isLoading, data } = useQuery(
    ["listAllPrices"],
    () => {
      return api
        .get("/api/prices")
        .then((res) => res.data)
        .catch((err) => {});
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const columns = [
    {
      title: "Turma",
      dataIndex: "grade",
      key: "grade",
      onFilter: (value, record) => record.grade.type.indexOf(value) === 0,
      filters: (data || [])
        .map((item) => {
          return {
            text: item.grade.type,
            value: item.grade.type,
          };
        })
        .filter(
          (item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index,
        ),
      render: (value) => {
        return <Tag color={value.color}>{value.type}</Tag>;
      },
    },
    {
      title: "Modalidade",
      dataIndex: "modality",
      key: "modality",
      onFilter: (value, record) => record.modality.name.indexOf(value) === 0,
      filters: (data || [])
        .map((item) => {
          return {
            text: item.modality.name,
            value: item.modality.name,
          };
        })
        .filter(
          (item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index,
        ),
      render: (value) => {
        return <Tag color={"blue"}>{value.name}</Tag>;
      },
    },
    {
      title: "Grupo",
      dataIndex: "group",
      key: "group",
      onFilter: (value, record) => record.group.name.indexOf(value) === 0,
      filters: (data || [])
        .map((item) => {
          return {
            text: item.group.name,
            value: item.group.name,
          };
        })
        .filter(
          (item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index,
        ),
      render: (value) => {
        return (
          <Tag title={value.description} color={"purple"}>
            {value.name}
          </Tag>
        );
      },
    },
    {
      title: "Aluno (R$/hora)",
      dataIndex: "amount",
      key: "amount",
      render: (value) => {
        return <span>{MaskMoney(value)}</span>;
      },
    },
    {
      title: "Aluno (R$/aula)",
      dataIndex: "amount",
      key: "amount_class",
      render: (value, item) => {
        return (
          <span>
            {MaskMoney(value * duration)}
            {" - "}
            {duration === 1 ? "1 hora" : `${duration} horas`}
          </span>
        );
      },
    },
    {
      title: "Professor (R$/hora)",
      dataIndex: "amount_teacher",
      key: "amount_teacher",
      render: (value) => {
        return <span>{MaskMoney(value)}</span>;
      },
    },
    {
      title: "Professor (R$/aula)",
      dataIndex: "amount_teacher",
      key: "amount_teacher_class",
      render: (value, item) => {
        return (
          <span>
            {MaskMoney(value * duration)}
            {" - "}
            {duration === 1 ? "1 hora" : `${duration} horas`}
          </span>
        );
      },
    },
    {
      title: "Vigência Início",
      dataIndex: "start_date",
      key: "start_date",
      onFilter: (value, record) => record.start_date.indexOf(value) === 0,
      filters: (data || [])
        .map((item) => {
          return {
            text: MaskDate(item.start_date),
            value: item.start_date,
          };
        })
        .filter(
          (item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index,
        ),
      render: (value) => {
        return <span>{MaskDate(value)}</span>;
      },
    },
    {
      title: "Vigência Fim",
      dataIndex: "end_date",
      key: "end_date",
      onFilter: (value, record) => record.end_date.indexOf(value) === 0,
      filters: (data || [])
        .map((item) => {
          return {
            text: MaskDate(item.end_date),
            value: item.end_date,
          };
        })
        .filter(
          (item, index, self) =>
            self.findIndex((t) => t.value === item.value) === index,
        ),
      render: (value) => {
        return <span>{MaskDate(value)}</span>;
      },
    },
  ];

  return (
    <Space direction={"vertical"} size={16} style={{ display: "flex" }}>
      <Card
        title="Orçamentos"
        extra={
          <Input
            title={"Duração aula"}
            type="number"
            step="0.5"
            min="1"
            defaultValue="1"
            suffix="hora(s)"
            onChange={(e) => setDuration(Number(e.target.value))}
          />
        }
      >
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          size={"small"}
        />
      </Card>
    </Space>
  );
};

export default Prices;
