import React, { useEffect } from "react";
import useTitleStore from "~/stores/useTitleStore";
import api from '~/services/axios'
import { useQuery } from "@tanstack/react-query";
import { Button, Card, Checkbox, notification, Row, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Table from "~/components/Table";
import { queryClient } from "~/services/queryClient";

interface Roles {
  id: string;
  name: string;
}

interface RolesCRUD {
  id: string;
  name: string;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

interface DataType {
  id: string;
  name: string;
  roles: string[];
  possibleRoles: Roles[];
}

const Permissions = () => {
  const { setTitle } = useTitleStore();
  const { id } = useParams();

  const [roles, setRoles] = React.useState<RolesCRUD[]>([]);
  const [initialRoles, setInitialRoles] = React.useState<RolesCRUD[]>([]);

  const dictionary = {
    rooms: "Salas",
    grades: "Turmas",
    profiles: "Perfis",
    dashboard: "Dashboard",
    events: "Aulas",
    subjects: "Disciplinas",
    teachers: "Professores",
    users: "Usuários",
    schedules: "Agenda",
    students: "Alunos",
    permissions: "Permissões",
  };

  useEffect(() => {
    setTitle({
      title: "Permissões",
      subTitle: "Customizar permissões de perfil",
      routes: [
        { path: "/portal/admin", breadcrumbName: "Administrativo" },
        {
          path: "/portal/admin/profiles",
          breadcrumbName: "Perfis",
        },
        {
          path: `/portal/admin/profiles/${id}`,
          breadcrumbName: "Permissões",
        },
      ],
    });
  }, []);

  const { isLoading, data, isFetching } = useQuery(["listGetProfile"], () => {
    return api
      .get(`/api/profiles/${id}`)
      .then((res) => res.data)
      .catch((err) => {});
  });

  useEffect(() => {
    // all possibleRoles name to array
    if (data) {
      const roles = _.uniq(
        data.possibleRoles.map((item) => item.name.split(":")[1]),
      );

      const rolesObj = roles.map((role) => {
        return {
          id: role,
          name: role,
          create: data.roles.includes(`create:${role}`),
          read: data.roles.includes(`read:${role}`),
          update: data.roles.includes(`update:${role}`),
          delete: data.roles.includes(`delete:${role}`),
        };
      });

      setRoles(rolesObj);
      setInitialRoles(rolesObj);
    }
  }, [isLoading, data, isFetching]);

  const updateRole = (role: string, action: string, checked: boolean) => {
    const newRoles = roles.map((item) => {
      if (item.id === role) {
        return { ...item, [action]: checked };
      }
      return item;
    });
    setRoles(newRoles);
  };

  const saveRoles = () => {
    const role: string[] = [];

    roles.forEach((item: RolesCRUD) => {
      if (item.create) role.push(`create:${item?.id}`);
      if (item.read) role.push(`read:${item?.id}`);
      if (item.update) role.push(`update:${item?.id}`);
      if (item.delete) role.push(`delete:${item?.id}`);
      return role;
    });

    // filter data.possibleRoles base on role array names
    const possibleRoles = data.possibleRoles.filter((item) => {
      return role.includes(item.name);
    });

    // put request
    api
      .put(`/api/profiles/${id}`, { roles: possibleRoles })
      .then((res) => {
        // refatch query
        queryClient.invalidateQueries(["listGetProfile"]);
        notification.success({
          message: "Permissões atualizadas com sucesso!",
          description:
            "Os usuários com esse perfil já podem navegar com as novas permissões.",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Erro ao atualizar permissões",
          description:
            "Ocorreu um erro ao atualizar as permissões, tente novamente mais tarde.",
        });
      });
  };

  const columns: ColumnsType<RolesCRUD> = [
    {
      title: "Perfil",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        const textLocal = dictionary[text] ? dictionary[text] : text;
        return (
          textLocal.toUpperCase().charAt(0) + textLocal.slice(1).toLowerCase()
        );
      },
    },
    {
      title: "Visualizar",
      dataIndex: "read",
      key: "read",
      width: "100px",
      align: "center",
      render: (text, record) => (
        <Checkbox
          key={`read:${record.id}`}
          checked={text}
          onChange={(e) => {
            updateRole(record.id, "read", e.target.checked);
          }}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ),
    },
    {
      title: "Criar",
      dataIndex: "create",
      key: "create",
      width: "100px",
      align: "center",
      render: (text, record) => (
        <Checkbox
          key={`create:${record.id}`}
          checked={text}
          onChange={(e) => {
            updateRole(record.id, "create", e.target.checked);
          }}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ),
    },
    {
      title: "Editar",
      dataIndex: "update",
      key: "update",
      width: "100px",
      align: "center",
      render: (text, record) => (
        <Checkbox
          key={`update:${record.id}`}
          checked={text}
          onChange={(e) => {
            updateRole(record.id, "update", e.target.checked);
          }}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ),
    },
    {
      title: "Excluir",
      dataIndex: "delete",
      key: "delete",
      width: "100px",
      align: "center",
      render: (text, record) => (
        <Checkbox
          key={`delete:${record.id}`}
          checked={text}
          onChange={(e) => {
            updateRole(record.id, "delete", e.target.checked);
          }}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ),
    },
  ];

  return (
    <Space direction={"vertical"} size={16} style={{ display: "flex" }}>
      <Card
        title="Listagem de Perfis"
        extra={<a href="~/pages/Admin/Permissions/index#">+ Adicionar</a>}
      >
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={roles}
          size={"small"}
          pagination={false}
        />

        <Row justify="end" style={{ marginTop: "1rem" }}>
          <Space>
            <Button
              type="default"
              onClick={() => {
                queryClient.invalidateQueries(["listGetProfile"]);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              disabled={_.isEqual(roles, initialRoles)}
              onClick={() => {
                saveRoles();
              }}
            >
              Salvar
            </Button>
          </Space>
        </Row>
      </Card>
    </Space>
  );
};

export default Permissions;
