import {
  Table as AntTable,
  Card,
  Col,
  Form,
  List,
  PaginationProps,
  Row,
  Select,
  TableProps,
  Typography,
} from 'antd'
import _ from "lodash";
import React from "react";
import { useMediaQuery } from "react-responsive";

export interface ResponsibleTableProps extends TableProps<any> {
  breakpointMobile?: number;
}

export interface PagProps extends PaginationProps {}

const Table = (props: ResponsibleTableProps) => {
  const [form] = Form.useForm();
  const [watchValues, setWatchValues] = React.useState({});
  const { breakpointMobile, ...rest } = props;

  const { Text } = Typography;

  const matches = useMediaQuery({
    query: "(max-width: 1050px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const filters =
    rest?.columns?.filter(
      (column) => column.filters && column.filters.length > 0,
    ) || [];

  const dataSource = props.dataSource?.map((item) => {
    const obj = {};

    props.columns?.forEach((column) => {
      obj[column.dataIndex] = {
        title: column.title,

        // get value from item base on dataIndex path json
        value: _.isArray(column?.dataIndex)
          ? column?.dataIndex?.reduce((o, i) => o[i] || "", item)
          : item[column?.dataIndex],

        // value: item[column.dataIndex],

        dataIndex: column?.dataIndex,
        render: column.render,
        id: item.id,
      };
    });
    return obj;
  });

  const filterDataSources = dataSource?.filter((item) => {
    return Object.keys(watchValues).every((key) => {
      if (watchValues[key]?.length > 0) {
        if (typeof item[key]?.value === "object") {
          return (
            watchValues[key].includes(
              item[key]?.value?.name || item[key]?.value?.type,
            ) ||
            (Array.isArray(item[key]?.value) &&
              item[key]?.value?.some((value) =>
                watchValues[key].includes(value?.name || value?.type),
              ))
          );
        }

        if (Array.isArray(item[key]?.value)) {
          return item[key]?.value?.some((value) =>
            watchValues[key].includes(value?.name || value?.type),
          );
        }

        if (typeof item[key]?.value === "string") {
          return watchValues[key].includes(item[key]?.value);
        }

        if (typeof item[key]?.value === "number") {
          return watchValues[key].includes(item[key]?.value);
        }
        console.log("isOther", item[key]?.value);
      }
      return true;
    });
  });

  if (matches) {
    const pagination: PagProps = (rest?.pagination as PagProps) || undefined;

    return (
      <>
        {filters?.length > 0 && (
          <Form form={form} layout="vertical" colon={true}>
            <Row gutter={[16, 16]}>
              {filters?.map((filter) => (
                <Col span={12} key={filter.title}>
                  <Form.Item
                    key={filter.dataIndex}
                    name={filter.dataIndex}
                    style={{ marginBottom: "0px" }}
                    // label={filter.title}
                  >
                    <Select
                      mode="multiple"
                      loading={props.loading}
                      placeholder={<>Selecione {filter.title}</>}
                      allowClear
                      onChange={(value) => {
                        form.setFieldsValue({ [filter.dataIndex]: value });
                        setWatchValues(form.getFieldsValue());
                      }}
                    >
                      {filter.filters?.map((filter) => (
                        <Select.Option key={filter.value} value={filter.text}>
                          {filter.text}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        )}

        <List
          header={props.title}
          grid={{
            gutter: 16,
            column: filterDataSources?.length <= 2 || isMobile ? 1 : 2,
          }}
          style={{ padding: "24px" }}
          loading={props.loading}
          dataSource={filterDataSources}
          pagination={pagination?.pageSize ? pagination : false}
          renderItem={(item, index) => (
            <List.Item style={{ padding: "0" }}>
              <Card styles={{ body: { padding: "16px" } }}>
                {Object.keys(item).map((key) => {
                  const column = rest?.columns?.find(
                    (column) => column?.dataIndex === item[key].dataIndex,
                  );

                  const row = item[key];

                  const dataSourceItem = props?.dataSource?.filter(
                    (x) => x.id === item[key].id,
                  )[0];

                  return (
                    <Row
                      key={key}
                      justify={"space-between"}
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        minHeight: 32,
                      }}
                    >
                      <Col
                        span={9}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Text strong>{column?.title}</Text>
                      </Col>
                      <Col span={14}>
                        {row.render
                          ? row.render(row.value, dataSourceItem)
                          : row.value}
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }

  return <AntTable {...rest} />;
};

export default Table;
