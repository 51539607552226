import styled from "styled-components";

import colors from "../../styles/colors";

const { primary, gray } = colors;

export const LoadingScreen = styled.div`
  display: flex;
  background-color: ${primary._000};
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  @-webkit-keyframes coffee {
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  }
  @keyframes coffee {
    100% {
      opacity: 0;
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  }

  .coffee {
    background: ${gray._000};
    display: grid;
    border-radius: 4px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    height: 40px;
    position: relative;
    width: 40px;
    padding: 2px;
    justify-items: center;
  }

  .coffee:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: '';
    height: 26px;
    width: 20px;
    border: 4px solid ${gray._000};
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: -10px;
    left: 100%;
    border-radius: 4px;
  }
  .coffee div {
    height: 20px;
    width: 8px;
    background: ${gray._000};
    opacity: 0.3;
    -webkit-animation: coffee 2s infinite;
    animation: coffee 2s infinite;
  }
  .coffee div:nth-child(1) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .coffee div:nth-child(2) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .coffee div:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
`;
