import { create } from "zustand";
import Header from "../components/Header";
import React, { ReactNode } from 'react'

interface RoutesProps {
  path: string;
  breadcrumbName: string;
  children?: RoutesProps[];
}

export interface TitleProps {
  title?: string | ReactNode;
  subTitle?: string;
  routes?: RoutesProps[];
  isDisabled?: boolean;
  avatar?: {};
  extra?: ReactNode[];
  tags?: ReactNode[];
}

interface State extends TitleProps {
  setTitle: (header: TitleProps) => void;
  setIsDisabled: (isDisabled: boolean) => void;
  TitleComponent: React.FC<TitleProps>;
}

const useTitleStore = create<State>()((set, get) => ({
  title: "Titulo",
  subTitle: "",
  routes: [],
  isDisabled: true,
  extra: [],
  avatar: {},
  tags: [],
  setTitle: ({
    title,
    subTitle,
    routes,
    extra = [],
    avatar = {},
    tags = [],
  }) => {
    set((state) => ({
      title,
      subTitle,
      routes,
      extra,
      avatar,
      tags,
      isDisabled: false,
    }));
  },
  setIsDisabled: (isDisabled) => {
    set((state) => ({ isDisabled }));
  },
  TitleComponent: () => (
    <Header
      title={get()?.title}
      routes={get()?.routes}
      subTitle={get()?.subTitle}
      isDisabled={get()?.isDisabled}
      extra={get()?.extra}
      avatar={get()?.avatar}
      tags={get()?.tags}
    />
  ),
}));

export default useTitleStore;
