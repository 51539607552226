import { PageHeader as Header } from "@ant-design/pro-layout";
import styled from 'styled-components';
import colors from '~/styles/colors';

const { gray, primary } = colors;

export const PageHeader = styled(Header)`
  background-color: ${primary._000} !important;
  padding: 30px;
  padding-bottom: 100px;

  .ant-page-header-heading-title {
    color: ${gray._000};
  }

  .ant-page-header-heading-sub-title {
    color: ${gray._000};
  }
  .breadcrumb-link {
    cursor: pointer;
  }
`;
