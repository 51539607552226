import { useQuery } from "@tanstack/react-query";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Statistic,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import type { TableProps } from "antd/es/table";
import Compressor from "compressorjs";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Cropper from "react-easy-crop";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "~/components/Table";
import { MaskDate } from "~/helpers/Masks";
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import useTitleStore from "~/stores/useTitleStore";

const DashOffice = () => {
  const navigate = useNavigate();
  const { setTitle } = useTitleStore();

  const [eventsTodayPage, setEventsTodayPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [eventsFuturePage, setEventsFuturePage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [eventsPaymentsPage, setEventsPaymentsPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [eventsAvaliationsPage, setEventsAvaliationsPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [eventsRequestsPage, setEventsRequestsPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [eventsCancelledPage, setEventsCancelledPage] = useState({
    page: 1,
    pageSize: 10,
  });
  const [eventsPage, setEventsPage] = useState({ page: 1, pageSize: 10 });

  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);

  const [currentTab, setCurrentTab] = useState("day");

  const query = new URLSearchParams(useLocation().search);
  const [currentDate, setCurrentDate] = useState(() =>
    query.get("date") ? dayjs(query.get("date")) : dayjs(),
  );

  useEffect(() => {
    query.set("date", currentDate.format("YYYY-MM-DD"));
    navigate({ search: query.toString() }, { replace: true });
  }, [currentDate]);

  const [eventsDates, setEventsDates] = useState([]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadedImage, setUploadedImage] = useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = 0;

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation,
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    const file = await new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });

    return file;
  }

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, 0);

      setCroppedImage(croppedImage);
    },
    [getCroppedImg, imageSrc],
  );

  const updateImage = async () => {
    if (!croppedImage) {
      notification.error({
        message: "Erro ao atualizar imagem",
        description: "Atualize a pagina e tente novamente",
      });
    }
    const file = await fetch(croppedImage).then((r) => r.blob());

    const compressedFile = await new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.8,
        width: 128,
        height: 128,
        success(result) {
          resolve(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    });

    const formData = new FormData();

    formData.append("picture", compressedFile);

    api
      .post(`/api/users/${user?.id}/picture`, formData, {
        headers: {
          contentType: "multipart/form-data",
        },
      })
      .then((res) => {
        setUploadedImage(false);
        setImageSrc(null);
        notification.success({
          message: "Foto de perfil atualizada com sucesso!",
        });
        queryClient.refetchQueries(["me"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  const { Text, Title } = Typography;

  useEffect(() => {
    setTitle({
      title: (
        <>
          {`Olá, ${user?.name}`}{" "}
          <img
            src={"../../assets/img/icons/waving-hand.png"}
            width={24}
            style={{ marginBottom: ".5rem" }}
          />
          <br />
          <Text type="secondary" style={{ fontSize: ".7rem" }}></Text>
        </>
      ),
      avatar: {
        src: user?.picture,
        id: user?.id,
      },
      subTitle: `${
        "São Paulo, " +
        Intl.DateTimeFormat("pt-BR", {
          localeMatcher: "best fit",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(new Date())
      }`,
      routes: [],
    });
  }, []);

  const { isLoading: isLoadingFilterTeacher, data: filterTeacher } = useQuery(
    ["filterTeacher"],
    () => {
      return api
        .get("/api/filters/teachers", {
          params: {},
        })
        .then((res) => res.data || [])
        .catch((err) => {});
    },
  );

  const { isLoading: isLoadingFilterStudent, data: filterStudent } = useQuery(
    ["filterStudent"],
    () => {
      return api
        .get("/api/filters/students", {
          params: {},
        })
        .then((res) => res.data || [])
        .catch((err) => {});
    },
  );

  const { isLoading, data: user } = useQuery(["me"], () => {
    return api.get("/api/user/me").then((res) => res.data);
  });

  const { isLoading: isLoadingEventsFuture, data: eventsFuture } = useQuery(
    [
      "listAllFutureEvents",
      eventsFuturePage?.page,
      eventsFuturePage?.pageSize,
      selectedStudent,
      selectedTeachers,
    ],
    () => {
      return api
        .get("/api/events", {
          params: {
            future: true,
            datailed: true,
            page: eventsFuturePage?.page || 1,
            pageSize: eventsFuturePage?.pageSize || 10,
            students: selectedStudent,
            teachers: selectedTeachers,
          },
        })
        .then((res) => {
          return res?.data;
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingListDates, data: listDates } = useQuery(
    ["listAllDates"],
    () => {
      return api
        .get("/api/events/list-dates", {
          params: {},
        })
        .then((res) => {
          return res?.data;
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingEvents, data: events } = useQuery(
    [
      "listAllEvents",
      currentDate ? currentDate?.set("date", 1).format("YYYY-MM-DD") : "",
      eventsPage?.page,
      eventsPage?.pageSize,
      selectedStudent,
      selectedTeachers,
    ],
    () => {
      return api
        .get("/api/events", {
          params: {
            date: currentDate
              ? currentDate?.set("date", 1).format("YYYY-MM-DD")
              : "",
            datailed: true,
            page: eventsPage?.page || 1,
            pageSize: eventsPage?.pageSize || 10,
            students: selectedStudent,
            teachers: selectedTeachers,
          },
        })
        .then((res) => res.data)
        .catch((err) => {});
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingEventsToday, data: eventsToday } = useQuery(
    [
      "listAllEventsToday",
      currentDate?.format("YYYY-MM-DD") || "",
      eventsTodayPage?.page,
      eventsTodayPage?.pageSize,
      selectedStudent,
      selectedTeachers,
    ],
    () => {
      return api
        .get("/api/events", {
          params: {
            today: true,
            date: currentDate ? currentDate?.format("YYYY-MM-DD") : "",
            datailed: true,
            page: eventsTodayPage?.page || 1,
            pageSize: eventsTodayPage?.pageSize || 10,
            students: selectedStudent,
            teachers: selectedTeachers,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingEventsAvaliations, data: eventsAvaliations } =
    useQuery(
      [
        "listAllEventsAvaliations",
        eventsAvaliationsPage?.page,
        eventsAvaliationsPage?.pageSize,
        selectedStudent,
        selectedTeachers,
      ],
      () => {
        return api
          .get("/api/events", {
            params: {
              status: "Avaliação: Pendente",
              datailed: true,
              page: eventsAvaliationsPage?.page || 1,
              pageSize: eventsAvaliationsPage?.pageSize || 10,
              students: selectedStudent,
              teachers: selectedTeachers,
            },
          })
          .then((res) => res.data)
          .catch((err) => {});
      },
      {
        cacheTime: 0,
      },
    );

  const { isLoading: isLoadingEventsCancelled, data: eventsCancelled } =
    useQuery(
      [
        "listAllEventsCancelled",
        eventsCancelledPage?.page,
        eventsCancelledPage?.pageSize,
        selectedStudent,
        selectedTeachers,
      ],
      () => {
        return api
          .get("/api/events", {
            params: {
              status: "Cancelado",
              datailed: true,
              page: eventsCancelledPage?.page || 1,
              pageSize: eventsCancelledPage?.pageSize || 10,
              students: selectedStudent,
              teachers: selectedTeachers,
            },
          })
          .then((res) => res.data)
          .catch((err) => {});
      },
      {
        cacheTime: 0,
      },
    );

  const { isLoading: isLoadingEventsRequests, data: eventsRequests } = useQuery(
    [
      "listAllEventsRequests",
      eventsRequestsPage?.page,
      eventsRequestsPage?.pageSize,
      selectedStudent,
      selectedTeachers,
    ],
    () => {
      return api
        .get("/api/events", {
          params: {
            status: "Solicitado",
            datailed: true,
            page: eventsRequestsPage?.page || 1,
            pageSize: eventsRequestsPage?.pageSize || 10,
            students: selectedStudent,
            teachers: selectedTeachers,
          },
        })
        .then((res) => res.data)
        .catch((err) => {});
    },
    {
      cacheTime: 0,
    },
  );

  const { isLoading: isLoadingEventsPayments, data: eventsPayments } = useQuery(
    [
      "listAllEventsPayments",
      eventsPaymentsPage?.page,
      eventsPaymentsPage?.pageSize,
      selectedStudent,
      selectedTeachers,
    ],
    () => {
      return api
        .get("/api/events", {
          params: {
            payments: true,
            datailed: true,
            page: eventsPaymentsPage?.page || 1,
            pageSize: eventsPaymentsPage?.pageSize || 10,
            students: selectedStudent,
            teachers: selectedTeachers,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (isLoadingListDates) {
      return;
    }

    setEventsDates(listDates);
  }, [isLoadingListDates]);

  const { isLoading: isLoadingEventsStats, data: eventStats } =
    useQuery<IEvents>(["eventStats"], () => {
      return api
        .get("/api/statistics/events")
        .then((res) => res.data)
        .catch((err) => {
          notification.error({
            message: "Erro ao carregar dados de estatísticas de aulas",
          });
        });
    });

  const currentDataTable = useMemo(() => {
    switch (currentTab) {
      case "day":
        return eventsToday;
      case "future":
        return eventsFuture;
      case "open-payments":
        return eventsPayments;
      case "pending-avaliations":
        return eventsAvaliations;
      case "awaiting-confirmation":
        return eventsRequests;
      case "events-cancelled":
        return eventsCancelled;
      default:
        return events;
    }
  }, [
    currentTab,
    eventsToday,
    eventsFuture,
    eventsPayments,
    eventsAvaliations,
    eventsRequests,
    eventsCancelled,
    events,
  ]);

  const currentDataPages = {
    day: setEventsTodayPage,
    future: setEventsFuturePage,
    "open-payments": setEventsPaymentsPage,
    "pending-avaliations": setEventsAvaliationsPage,
    "awaiting-confirmation": setEventsRequestsPage,
    "events-cancelled": setEventsCancelledPage,
    default: setEventsPage,
  };

  const currentDataTableLoading = useMemo(() => {
    switch (currentTab) {
      case "day":
        return isLoadingEventsToday;
      case "future":
        return isLoadingEventsFuture;
      case "open-payments":
        return isLoadingEventsPayments;
      case "pending-avaliations":
        return isLoadingEventsAvaliations;
      case "awaiting-confirmation":
        return isLoadingEventsRequests;
      case "events-cancelled":
        return isLoadingEventsCancelled;
      default:
        return isLoadingEvents;
    }
  }, [
    currentTab,
    isLoadingEventsToday,
    isLoadingEventsFuture,
    isLoadingEventsPayments,
    isLoadingEventsAvaliations,
    isLoadingEventsCancelled,
    isLoadingEventsRequests,
    isLoadingEvents,
  ]);

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    setSelectedTeachers(filters?.teachers || []);
    setSelectedStudent(filters?.students || []);

    if (extra?.action === "filter") {
      setEventsTodayPage({ page: 1, pageSize: 10 });
      setEventsFuturePage({ page: 1, pageSize: 10 });
      setEventsPaymentsPage({ page: 1, pageSize: 10 });
      setEventsAvaliationsPage({ page: 1, pageSize: 10 });
      setEventsRequestsPage({ page: 1, pageSize: 10 });
      setEventsCancelledPage({ page: 1, pageSize: 10 });
      setEventsPage({ page: 1, pageSize: 10 });
    }
  };

  return (
    <Space direction={"vertical"} size={16} style={{ display: "flex" }}>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} xl={24}>
          <Row gutter={[16, 16]}>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingEventsFuture}
                bordered={false}
                style={{ height: "100%" }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas agendadas para data de hoje">
                      Aulas hoje
                    </Tooltip>
                  }
                  value={eventStats?.today}
                  valueRender={(value) => (
                    <Typography.Link
                      style={{ fontSize: "24px" }}
                      onClick={() => setCurrentTab("day")}
                    >
                      {value}
                    </Typography.Link>
                  )}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingEventsFuture}
                bordered={false}
                style={{ height: "100%" }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas agendadas para os proximos dias">
                      Aulas futuras
                    </Tooltip>
                  }
                  value={eventStats?.upcoming}
                  valueRender={(value) => (
                    <Typography.Link
                      style={{ fontSize: "24px" }}
                      onClick={() => setCurrentTab("future")}
                    >
                      {value}
                    </Typography.Link>
                  )}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingEventsFuture}
                bordered={false}
                style={{ height: "100%" }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas com pagamentos em aberto">
                      Pagamentos em aberto
                    </Tooltip>
                  }
                  value={eventStats?.students_open_payments || 0}
                  valueRender={(value) => (
                    <Typography.Link
                      style={{ fontSize: "24px" }}
                      onClick={() => setCurrentTab("open-payments")}
                    >
                      {value}
                    </Typography.Link>
                  )}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingEventsFuture}
                bordered={false}
                style={{ height: "100%" }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas solicitadas e aguardando confirmação e disponibilidade do professor/aluno">
                      Solicitações
                    </Tooltip>
                  }
                  value={new Intl.NumberFormat("pt-BR", {}).format(
                    eventStats?.status?.Solicitado || 0,
                  )}
                  valueRender={(value) => (
                    <Typography.Link
                      style={{ fontSize: "24px" }}
                      onClick={() => setCurrentTab("awaiting-confirmation")}
                    >
                      {value}
                    </Typography.Link>
                  )}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingEventsFuture}
                bordered={false}
                style={{ height: "100%" }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas aguardando avaliação dos professores">
                      Avaliações pendentes
                    </Tooltip>
                  }
                  value={new Intl.NumberFormat("pt-BR", {}).format(
                    eventStats?.status?.["Avaliação: Pendente"] || 0,
                  )}
                  valueRender={(value) => (
                    <Typography.Link
                      style={{ fontSize: "24px" }}
                      onClick={() => setCurrentTab("pending-avaliations")}
                    >
                      {value}
                    </Typography.Link>
                  )}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingEventsFuture}
                bordered={false}
                style={{ height: "100%" }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas canceladas">Aulas canceladas</Tooltip>
                  }
                  value={eventStats?.status?.Cancelado || 0}
                  valueRender={(value) => (
                    <Typography.Link
                      style={{ fontSize: "24px" }}
                      onClick={() => setCurrentTab("events-cancelled")}
                    >
                      {value}
                    </Typography.Link>
                  )}
                />
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24}>
              <Card size={"small"} bordered={false} style={{ height: "100%" }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} xl={18}>
                    <Title level={5}>Agenda</Title>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    xl={6}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <Select
                      style={{
                        width: currentTab === "day" ? "49%" : "100%",
                        marginRight: "14px",
                      }}
                      defaultValue={"day"}
                      allowClear={false}
                      value={currentTab}
                      onSelect={(value) => setCurrentTab(value)}
                      options={[
                        { label: `Aulas do dia`, value: "day" },
                        { label: "Próximas aulas", value: "future" },
                        {
                          label: "Pagamentos em aberto",
                          value: "open-payments",
                        },
                        {
                          label: "Aguardando confirmação",
                          value: "awaiting-confirmation",
                        },
                        {
                          label: "Avaliações pendentes",
                          value: "pending-avaliations",
                        },
                        {
                          label: "Aulas canceladas",
                          value: "events-cancelled",
                        },
                      ]}
                    />
                    {currentTab === "day" && (
                      <DatePicker
                        style={{ width: "49%" }}
                        allowClear={false}
                        defaultValue={dayjs(currentDate)}
                        value={dayjs(currentDate)}
                        onChange={(date) => setCurrentDate(date)}
                        format={"DD/MM/YYYY"}
                        dateRender={(current) => {
                          // if date on array render badge
                          const isDate = eventsDates?.includes(
                            current.format("YYYY-MM-DD"),
                          );

                          return isDate ? (
                            <Badge
                              color={"#ff3b18"}
                              text={current.format("DD")}
                            />
                          ) : (
                            <span>{current.format("DD")}</span>
                          );
                        }}
                      />
                    )}
                  </Col>
                </Row>

                <Table
                  size={"small"}
                  loading={currentDataTableLoading}
                  dataSource={currentDataTable?.data}
                  columns={[
                    {
                      title: "Data",
                      dataIndex: "date",
                      key: "date",
                      render: (date) => (MaskDate(date) ? MaskDate(date) : "-"),
                    },
                    {
                      title: "Modalidade",
                      dataIndex: ["modality", "name"],
                      key: "modality",
                    },
                    {
                      title: "Sala",
                      dataIndex: "room",
                      key: "room",
                      render: (room) => {
                        return (
                          <Tag color={room?.color} key={room?.name}>
                            {room?.name}
                          </Tag>
                        );
                      },
                    },

                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      render: (status) => {
                        return (
                          <Tag color={status?.color} key={status?.name}>
                            {status?.name}
                          </Tag>
                        );
                      },
                    },
                    {
                      title: "Professor",
                      dataIndex: "teachers",
                      key: "teachers",
                      filterSearch: true,
                      filters: filterTeacher?.map((item) => ({
                        text: item.label,
                        value: item.value,
                      })),
                      render: (teachers) => {
                        return teachers?.map((teacher) => teacher?.name);
                      },
                    },
                    {
                      title: "Aluno",
                      dataIndex: "students",
                      filters: filterStudent?.map((student) => ({
                        text: student.label,
                        value: student.value,
                      })),
                      filterSearch: true,
                      key: "students",
                      render: (students) => {
                        return students?.map(
                          (student, index) =>
                            student?.name +
                            (index !== students?.length - 1 ? ", " : ""),
                        );
                      },
                    },
                    {
                      title: "Inicio",
                      dataIndex: "int_time",
                      key: "int_time",
                      render: (int_time) => int_time,
                    },
                    {
                      title: "Ações",
                      key: "actions",
                      render: (item, _) => (
                        <Space size="middle">
                          <Button
                            type="link"
                            onClick={() => {
                              navigate(`/portal/events/${_.id}`);
                            }}
                          >
                            <a href={`/portal/events/${_.id}`}>Ver</a>
                          </Button>
                        </Space>
                      ),
                    },
                  ]}
                  rowKey={"id"}
                  onChange={onChange}
                  pagination={{
                    pageSize: currentDataTable?.per_page || 10,
                    total: currentDataTable?.total || 0,
                    current: currentDataTable?.current_page || 1,
                    onChange: (page, pageSize) => {
                      currentDataPages[currentTab]({ page, pageSize });
                    },
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        open={uploadedImage}
        onOk={() => updateImage()}
        onCancel={() => {
          setUploadedImage(false);
          setImageSrc(null);
        }}
      >
        {imageSrc ? (
          <Space
            direction={"vertical"}
            style={{ width: "100%", height: "300px", position: "relative" }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Space>
        ) : (
          <input type="file" onChange={onFileChange} accept="image/*" />
        )}
      </Modal>
    </Space>
  );
};

export default DashOffice;
