import { Typography } from "antd";
import { BaseType } from "antd/lib/typography/Base";
import React from 'react'
import { To, useHref, useLinkClickHandler } from 'react-router-dom'

// Define the props type
interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: To;
  replace?: boolean;
  state?: any;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  type?: BaseType;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    const href = useHref(to);

    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
    });

    return (
      <Typography.Link
        {...rest}
        href={href}
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (onClick) {
            onClick(event);
          }
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  },
);

Link.displayName = "CustomLink";

export default Link;
